import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import { Doughnut, Bar, Line } from "react-chartjs-2";
import { CashIcon, CogIcon, ExternalLinkIcon, SparklesIcon } from '@heroicons/react/outline';
var moment = require('moment');

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            stats: {},
            account: {},
            option: "page_loads",
            chart_options: {
                tooltips: {
                    mode: 'label'
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                scales: {
                    yAxes: [
                        {
                            gridLines: {
                                display: true
                            },
                            ticks: {
                                beginAtZero: true,
                                stepSize: 10
                            },
                            display: true
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                beginAtZero: true,
                                stepSize: 1
                            },
                            display: true
                        }
                    ]
                },
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
                maintainAspectRatio: false,
            },
            claim_alias: ""
        }
    };

    componentDidMount() {
        this.functions.getUser();
    }

    functions = {
        getUser: async () => {
            let user = userRegister.get();
            await this.promisedSetState({
                user: user ? user : {}
            });
            this.functions.getDashboard();
        },
        getDashboard: async () => {
            try {
                await this.promisedSetState({ loading: true });
                let response = await this.calls.dashboard();
                await this.promisedSetState({
                    stats: response.data,
                    account: response.data.account ? response.data.account : {}
                });
            } catch (error) { }
            await this.promisedSetState({
                loading: false
            });
        },
        checkAlias: async () => {
            await this.promisedSetState({ loading_claim: true, claim_error: false });
            try {
                await this.calls.checkAlias({ alias: this.state.claim_alias });
                if (window.location.hostname === 'localhost') {
                    this.props.history.push("/v1-proptree/billing?alias=" + this.state.claim_alias);
                } else {
                    this.props.history.push("/v1/billing?alias=" + this.state.claim_alias);
                }
            } catch (error) {
                await this.promisedSetState({
                    claim_error: error && error.body ? error.body.message : "Not a valid alias"
                });
            }
            await this.promisedSetState({ loading_claim: false });
        },
    };

    calls = {
        dashboard: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.proptree + "/dashboardProptree";
            return apiRegister.call(options, url);
        },
        checkAlias: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.proptree + "/checkAlias";
            return apiRegister.call(options, url);
        },
    };

    renders = {
        ctr: () => {
            let ctr = 0;
            try {
                let clicks = this.state.stats.clicks.this_month_total;
                let impressions = this.state.stats.page_loads.this_month_total;
                let ctr = (clicks / impressions) * 100;
                ctr = ctr.toFixed(2);
            } catch (error) { }
            return ctr;
        },
        chartColor: (option) => {
            let options = {
                "page_loads": "26, 213, 189",
                "clicks": "69, 63, 241",
                "leads": "234, 112, 30",
                "referral": "237, 100, 166",
                "incoming": "255, 196, 7"
            };
            return options[option];
        },
        lineChartData: () => {
            let data = [
                {
                    borderWidth: 2,
                    label: 'Inquiries',
                    borderColor: 'rgb(' + this.renders.chartColor("leads") + ')',
                    backgroundColor: 'rgba(' + this.renders.chartColor("leads") + ', 0.3)',
                    data: this.state.stats["leads"]["this_month_daily"].map((item) => { return item.value })
                },
                {
                    borderWidth: 2,
                    label: 'Clicks',
                    borderColor: 'rgb(' + this.renders.chartColor("clicks") + ')',
                    backgroundColor: 'rgba(' + this.renders.chartColor("clicks") + ', 0.3)',
                    data: this.state.stats["clicks"]["this_month_daily"].map((item) => { return item.value })
                },
                {
                    borderWidth: 2,
                    label: 'Views',
                    borderColor: 'rgb(' + this.renders.chartColor("page_loads") + ')',
                    backgroundColor: 'rgba(' + this.renders.chartColor("page_loads") + ', 0.3)',
                    data: this.state.stats["page_loads"]["this_month_daily"].map((item) => { return item.value })
                }
            ];
            if (this.state.account.subscription) {
                data.push({
                    borderWidth: 2,
                    label: 'Referred clicks',
                    borderColor: 'rgb(' + this.renders.chartColor("referral") + ')',
                    backgroundColor: 'rgba(' + this.renders.chartColor("referral") + ', 0.3)',
                    data: this.state.stats['referral']["clicks"]["this_month_daily"].map((item) => { return item.value })
                });
                data.push({
                    borderWidth: 2,
                    label: 'Referral clicks',
                    borderColor: 'rgb(' + this.renders.chartColor("incoming") + ')',
                    backgroundColor: 'rgba(' + this.renders.chartColor("incoming") + ', 0.3)',
                    data: this.state.stats['incoming']["clicks"]["this_month_daily"].map((item) => { return item.value })
                });
            }
            return data;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="grid grid-cols-12 sm:row-gap-10 row-gap-6 sm:col-gap-10 h-full min-h-full sm:p-0 p-6 pt-0">

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading ...</div>
                        </div>
                    </div>
                }

                {/* CLAIM CUSTOM NAME */}
                {
                    !this.state.loading &&
                    !this.state.account.subscription &&
                    this.state.user.type === "agent" &&
                    <div className="col-span-12 sm:-mb-4 flex flex-col items-center justify-center">
                        <div className="max-w-140 w-full">
                            <div style={{ backgroundImage: "linear-gradient(100deg, rgb(0, 50, 98) 8%, rgb(1, 28, 58) 92%)" }} className="border-t rounded-lg overflow-hidden">
                                <div className="px-4 py-5 sm:p-6">
                                    <h3 className="text-base font-semibold leading-6 text-white">Claim your custom shortname !</h3>
                                    <div className="mt-2 max-w-xl text-sm text-white">
                                        <p>
                                            Avail your 6-month free trial and get exclusive access to new features, customizations, and more insights.
                                        </p>
                                    </div>
                                    <div className="mt-4">
                                        <div className="flex">
                                            <div className="bg-gray-50 border text-sm border-r-none rounded-l-md shadow-sm items-center inline-flex sm:h-10 px-4">
                                                https://proptr.ee/
                                            </div>
                                            <input
                                                onChange={(event) => {
                                                    this.setState({
                                                        claim_alias: event.target.value
                                                    })
                                                }}
                                                placeholder="input shortname"
                                                value={this.state.claim_alias}
                                                type="text"
                                                name="proptree-shortname"
                                                id="proptree-shortname"
                                                className={"appearance-none block rounded-l-none w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"}
                                            />
                                        </div>
                                    </div>
                                    {
                                        this.state.claim_alias && this.state.claim_alias !== "" &&
                                        <div className="mt-5 w-full">
                                            <button
                                                onClick={async () => {
                                                    if (!this.state.loading_claim) {
                                                        this.functions.checkAlias();
                                                    }
                                                }}
                                                type="button"
                                                className="inline-flex relative items-center overflow-hidden rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                            >
                                                Claim Shortname
                                                <SparklesIcon className="w-5 ml-2"></SparklesIcon>
                                                {
                                                    this.state.loading_claim &&
                                                    <div className="absolute left-0 right-0 z-60 bg-indigo-600 bottom-0 top-0">
                                                        <div className="w-full h-full flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    }
                                    {
                                        this.state.claim_error &&
                                        <div className="bg-red-100 text-red-500 rounded-md text-sm p-3 px-4 font-medium mt-5">
                                            {this.state.claim_error}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* STATS */}
                {
                    !this.state.loading &&
                    this.state.stats &&
                    <div className="col-span-12 flex flex-col items-center justify-center">
                        <div className="max-w-140 w-full">
                            <div className="bg-white overflow-hidden shadow rounded-lg border">
                                <div className="px-4 py-5 sm:p-6">
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">Welcome</h3>
                                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                                        <p>
                                            <span className="italic leading-relaxed">
                                                Proptree is your personalized link-in-bio for real estate, always in-sync with your agency's CRM. Any promotion assigned to you will instantly reflect on your proptree. Your proptree keeps your network engaged, so you don’t have to.
                                            </span>
                                        </p>
                                        <br></br>
                                        <p className="font-bold">
                                            What's next ?
                                        </p>
                                        <p>
                                            1) copy your proptree link to all your social bios & WhatsApp
                                        </p>
                                        <p>
                                            2) your network will stay updated about your offer
                                        </p>
                                        <p>
                                            3) get valuable network traffic & inquiries
                                        </p>
                                    </div>
                                    <div className="flex mt-5 overflow-x-auto">
                                        <div className="">
                                            <button
                                                onClick={() => {
                                                    let user_name = this.state.user.shortId;
                                                    if (this.state.user.subscription || this.state.user.type !== "agent") {
                                                        if (this.state.user.alias && this.state.user.alias !== "") {
                                                            user_name = this.state.user.alias;
                                                        }
                                                    }
                                                    let proptree_url = "";
                                                    if (window.location.hostname === 'localhost') {
                                                        proptree_url = "http://localhost:5001/" + user_name + "/proptree";
                                                    } else {
                                                        proptree_url = "https://proptr.ee/" + user_name;
                                                    }
                                                    window.open(proptree_url, '_blank');
                                                }}
                                                type="button"
                                                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                            >
                                                Link
                                                <ExternalLinkIcon className="w-5 ml-2"></ExternalLinkIcon>
                                            </button>
                                        </div>
                                        <div className="flex flex-1"></div>
                                        {
                                            this.state.account.type == "agent" &&
                                            <div className="ml-2">
                                                <button
                                                    onClick={() => {
                                                        if (window.location.origin.indexOf('localhost') !== -1) {
                                                            this.props.history.push("/v1-proptree/settings");
                                                        } else {
                                                            this.props.history.push("/v1/settings");
                                                        }
                                                    }}
                                                    type="button"
                                                    className="inline-flex items-center rounded-md bg-gray-100 px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                                >
                                                    Settings
                                                    <CogIcon className="w-5 ml-2"></CogIcon>
                                                </button>
                                            </div>
                                        }
                                        {
                                            this.state.account.type == "agent" &&
                                            <div className="ml-4">
                                                <button
                                                    onClick={() => {
                                                        if (window.location.origin.indexOf('localhost') !== -1) {
                                                            this.props.history.push("/v1-proptree/billing");
                                                        } else {
                                                            this.props.history.push("/v1/billing");
                                                        }
                                                    }}
                                                    type="button"
                                                    className="inline-flex items-center rounded-md bg-gray-100 px-3 py-2 text-sm font-medium text-gray-900 hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                                >
                                                    Plans
                                                    <CashIcon className="w-5 ml-2"></CashIcon>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="max-w-140 w-full mt-6">
                            <div className="grid grid-cols-4 gap-4">
                                <div className="relative overflow-hidden border rounded-lg bg-white shadow col-span-4">
                                    <div className="px-4 py-5 pb-4 sm:p-6">
                                        <h3 className="text-base font-semibold leading-6 text-gray-900 col-span-4">Current month</h3>
                                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                                            <p>
                                                Follow insights generated from your network traffic to gain information about views, clicks, and inquiries.
                                            </p>
                                        </div>
                                    </div>
                                    <div className={this.state.account.subscription ? "grid grid-cols-3 px-2 pb-4 pt-2" : "grid grid-cols-3 px-2 pb-4 pt-2"}>
                                        <div className="col-span-1 h-full flex flex-col items-center justify-center px-4 sm:px-5"
                                             onMouseEnter={() => {
                                                 this.setState({
                                                     hover_views: true
                                                 });
                                             }}
                                             onMouseLeave={() => {
                                                 this.setState({
                                                     hover_views: false
                                                 });
                                             }}
                                             onTouchStart={() => {
                                                 this.setState({
                                                     hover_views: true
                                                 });
                                             }}
                                             onTouchEnd={() => {
                                                 this.setState({
                                                     hover_views: false
                                                 });
                                             }}>
                                            <div>
                                                <dt className="flex flex-row justify-center items-center"><div className="w-2 h-2 rounded-full bg-purple-500 mr-2" style={{ minWidth: "0.5rem" }}></div><p className="text-sm font-medium text-gray-900">{"Views"}</p></dt>
                                                <dd className="flex w-full">
                                                    <p className={"text-2xl w-full text-center font-semibold"}>{this.state.stats.page_loads ? this.state.stats.page_loads.this_month_total : '-'}</p>
                                                </dd>
                                                {
                                                    this.state.hover_views &&
                                                    <div style={{ width: "90px", marginTop: "-115px", marginLeft: "-20px" }} className="absolute bg-gray-800 text-white z-10 text-xxxs rounded-md font-medium px-4 py-2">
                                                        Views I received on my page
                                                        <div className="absolute" style={{
                                                            bottom: '-8px',
                                                            left: "14px",
                                                            width: 0,
                                                            height: 0,
                                                            borderLeft: "10px solid transparent",
                                                            borderRight: "10px solid transparent",
                                                            borderTop: "10px solid #343338"
                                                        }}></div>
                                                    </div>
                                                }
                                                {
                                                    false &&
                                                    <p className="text-xxxs font-light text-center text-gray-800 pt-1">{"Total page views"}</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-span-1 h-full flex flex-col items-center justify-center px-4 sm:px-5"
                                             onMouseEnter={() => {
                                                 this.setState({
                                                     hover_clicks: true
                                                 });
                                             }}
                                             onMouseLeave={() => {
                                                 this.setState({
                                                     hover_clicks: false
                                                 });
                                             }}
                                             onTouchStart={() => {
                                                 this.setState({
                                                     hover_clicks: true
                                                 });
                                             }}
                                             onTouchEnd={() => {
                                                 this.setState({
                                                     hover_clicks: false
                                                 });
                                             }}>
                                            <div>
                                                <dt className="flex flex-row justify-center items-center"><div className="w-2 h-2 rounded-full bg-purple-500 mr-2" style={{ minWidth: "0.5rem" }}></div><p className="text-sm font-medium text-gray-900">{"Clicks"}</p></dt>
                                                <dd className="flex w-full">
                                                    <p className={"text-2xl w-full text-center font-semibold"}>{this.state.stats.clicks ? this.state.stats.clicks.this_month_total : '-'}</p>
                                                </dd>
                                                {
                                                    this.state.hover_clicks &&
                                                    <div style={{ width: "90px", marginTop: "-115px", marginLeft: "-20px" }} className="absolute bg-gray-800 text-white z-10 text-xxxs rounded-md font-medium px-4 py-2">
                                                        Clicks I received to my listings
                                                        <div className="absolute" style={{
                                                            bottom: '-8px',
                                                            left: "14px",
                                                            width: 0,
                                                            height: 0,
                                                            borderLeft: "10px solid transparent",
                                                            borderRight: "10px solid transparent",
                                                            borderTop: "10px solid #343338"
                                                        }}></div>
                                                    </div>
                                                }
                                                {
                                                    false &&
                                                    <p className="text-xxxs font-light text-center text-gray-800 pt-1">{"Total listing clicks"}</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-span-1 h-full flex flex-col items-center justify-center px-4 sm:px-5"
                                             onMouseEnter={() => {
                                                 this.setState({
                                                     hover_leads: true
                                                 });
                                             }}
                                             onMouseLeave={() => {
                                                 this.setState({
                                                     hover_leads: false
                                                 });
                                             }}
                                             onTouchStart={() => {
                                                 this.setState({
                                                     hover_leads: true
                                                 });
                                             }}
                                             onTouchEnd={() => {
                                                 this.setState({
                                                     hover_leads: false
                                                 });
                                             }}>
                                            <div>
                                                <dt className="flex flex-row justify-center items-center"><div className="w-2 h-2 rounded-full bg-orange-500 mr-2" style={{ minWidth: "0.5rem" }}></div><p className="text-sm font-medium text-gray-900">{"Inquiries"}</p></dt>
                                                <dd className="flex w-full">
                                                    <p className={"text-2xl w-full text-center font-semibold"}>{this.state.stats.leads ? this.state.stats.leads.this_month_total : '-'}</p>
                                                </dd>
                                                {
                                                    this.state.hover_leads &&
                                                    <div style={{ width: "100px", marginTop: "-115px", marginLeft: "-20px" }} className="absolute bg-gray-800 text-white z-10 text-xxxs rounded-md font-medium px-4 py-2">
                                                        Inquiries I received from my listings
                                                        <div className="absolute" style={{
                                                            bottom: '-8px',
                                                            left: "14px",
                                                            width: 0,
                                                            height: 0,
                                                            borderLeft: "10px solid transparent",
                                                            borderRight: "10px solid transparent",
                                                            borderTop: "10px solid #343338"
                                                        }}></div>
                                                    </div>
                                                }
                                                {
                                                    false &&
                                                    <p className="text-xxxs font-light text-center text-gray-800 pt-1">{"Total listing inquiries"}</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.account.subscription &&
                                        <div className={this.state.account.subscription ? "grid grid-cols-2 px-2 pb-4 pt-2" : "grid grid-cols-2 px-2 pb-4 pt-2"}>
                                            {
                                                <div className="col-span-1 h-full flex flex-col items-center justify-center px-4 sm:px-5"
                                                     onMouseEnter={() => {
                                                        this.setState({
                                                            hover_referral: true
                                                        });
                                                     }}
                                                     onMouseLeave={() => {
                                                         this.setState({
                                                             hover_referral: false
                                                         });
                                                     }}
                                                     onTouchStart={() => {
                                                         this.setState({
                                                             hover_referral: true
                                                         });
                                                     }}
                                                     onTouchEnd={() => {
                                                         this.setState({
                                                             hover_referral: false
                                                         });
                                                     }}>
                                                    <div>
                                                        <dt className="flex flex-row justify-center items-center"><div className="w-2 h-2 rounded-full bg-pink-500 mr-2" style={{ minWidth: "0.5rem" }}></div><p className="text-sm font-medium text-gray-900 text-center">{"Referred clicks"}</p></dt>
                                                        <dd className="flex w-full">
                                                            <p className={"text-2xl w-full text-center font-semibold"}>{this.state.stats.referral && this.state.stats.referral.clicks ? this.state.stats.referral.clicks.this_month_total : '-'}</p>
                                                        </dd>
                                                        {
                                                            this.state.hover_referral &&
                                                            <div style={{ width: "110px", marginTop: "-115px", marginLeft: "-20px" }} className="absolute bg-gray-800 text-white z-10 text-xxxs rounded-md font-medium px-4 py-2">
                                                                Clicks I referred to other agents listings
                                                                <div className="absolute" style={{
                                                                    bottom: '-8px',
                                                                    left: "14px",
                                                                    width: 0,
                                                                    height: 0,
                                                                    borderLeft: "10px solid transparent",
                                                                    borderRight: "10px solid transparent",
                                                                    borderTop: "10px solid #343338"
                                                                }}></div>
                                                            </div>
                                                        }
                                                        {
                                                            false &&
                                                            <p className="text-xxxs font-light text-center text-gray-800 pt-1">{"Clicks I referred to other agents listings"}</p>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {
                                                <div className="col-span-1 h-full flex flex-col items-center justify-center px-4 sm:px-5"
                                                     onMouseEnter={() => {
                                                        this.setState({
                                                        hover_incoming: true
                                                        });
                                                     }}
                                                     onMouseLeave={() => {
                                                         this.setState({
                                                             hover_incoming: false
                                                         });
                                                     }}
                                                     onTouchStart={() => {
                                                         this.setState({
                                                             hover_incoming: true
                                                         });
                                                     }}
                                                     onTouchEnd={() => {
                                                         this.setState({
                                                             hover_incoming: false
                                                         });
                                                     }}>
                                                    <div>
                                                        <dt className="flex flex-row justify-center items-center"><div className="w-2 h-2 rounded-full bg-pink-500 mr-2" style={{ minWidth: "0.5rem" }}></div><p className="text-sm font-medium text-gray-900 text-center">{"Referral clicks"}</p></dt>
                                                        <dd className="flex w-full">
                                                            <p className={"text-2xl w-full text-center font-semibold"}>{this.state.stats.incoming && this.state.stats.incoming.clicks ? this.state.stats.incoming.clicks.this_month_total : '-'}</p>
                                                        </dd>
                                                        {
                                                            this.state.hover_incoming &&
                                                            <div style={{ width: "110px", marginTop: "-115px", marginLeft: "-20px" }} className="absolute bg-gray-800 text-white z-10 text-xxxs rounded-md font-medium px-4 py-2">
                                                                Clicks other agents referred to my listings
                                                                <div className="absolute" style={{
                                                                    bottom: '-8px',
                                                                    left: "14px",
                                                                    width: 0,
                                                                    height: 0,
                                                                    borderLeft: "10px solid transparent",
                                                                    borderRight: "10px solid transparent",
                                                                    borderTop: "10px solid #343338"
                                                                }}></div>
                                                            </div>
                                                        }
                                                        {
                                                            false &&
                                                            <p className="text-xxxs font-light text-center text-gray-800 pt-1">{"Clicks other agents referred to my listings"}</p>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.stats[this.state.option] &&
                                        Array.isArray(this.state.stats[this.state.option]["this_month_daily"]) &&
                                        <div className="w-full h-72 border-t relative p-5">
                                            <Line
                                                options={this.state.chart_options}
                                                data={{
                                                    labels: this.state.stats[this.state.option]['this_month_daily'].map(item => item.date.split('-')[2]),
                                                    datasets: this.renders.lineChartData()
                                                }}
                                            />
                                        </div>
                                    }
                                    <div className="px-4 py-5 pb-5 sm:p-6 border-t">
                                        <h3 className="text-base font-semibold leading-6 text-gray-900 col-span-4">Top 10 listings</h3>
                                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                                            <p>
                                                See how your inventory performs and get itemized insights.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="relative overflow-x-scroll">
                                        <table className="min-w-full">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="">
                                                        <span className="sr-only">Listing</span>
                                                    </th>
                                                    <th scope="col" className="">
                                                        <span className="sr-only">Clicks</span>
                                                    </th>
                                                    <th scope="col" className="">
                                                        <span className="sr-only">Inquiries</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            {
                                                this.state.stats.listings &&
                                                Array.isArray(this.state.stats.listings.clicks) &&
                                                this.state.stats.listings.clicks.map((item, index) => {
                                                    return (
                                                        <tbody>
                                                            <tr className="border-b">
                                                                <td className="flex items-center px-6 py-4">
                                                                    {
                                                                        Array.isArray(item.images) &&
                                                                        item.images.length > 0 &&
                                                                        <div
                                                                            style={{ backgroundImage: "url(" + item.images[0] + ")" }}
                                                                            className="min-h-10 min-w-10 bg-cover rounded-full border"></div>
                                                                    }
                                                                    {
                                                                        !Array.isArray(item.images) &&
                                                                        <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                                                                            <svg className="h-full w-full text-gray-300" width="24" height="20" viewBox="0 0 24 20" fill="none">
                                                                                <path d="M19.0316 0L19.9614 5.43362H24V20.2812H4.67806V18.9156L2.78923 19.2351L0 3.34155L19.0316 0ZM4.06803 17.4332L4.67817 17.3172V5.43339H18.3927L17.7533 1.80113L1.8017 4.61979L4.06803 17.4332ZM6.247 18.7119H22.4312V7.00255H6.247V18.7119ZM7.29291 16.7359V8.04847H21.3849V14.3535L18.1889 10.6632L17.5494 10.4018L12.6101 15.8352L10.5763 14.557L7.29291 16.7359ZM9.03633 10.4018C9.03633 11.1574 9.67568 11.7965 10.4309 11.7965C11.1864 11.7965 11.8257 11.1574 11.8254 10.4018C11.8254 9.64602 11.1864 9.00691 10.4309 9.00691C9.67568 9.00691 9.03633 9.64637 9.03633 10.4018Z" fill="currentColor" />
                                                                            </svg>
                                                                        </span>
                                                                    }
                                                                    <div className="pl-3" style={{ whiteSpace: "nowrap" }}>
                                                                        <div
                                                                            className="text-sm font-medium">
                                                                            {this.state.enterprise_project ? (item.projectName ? item.projectName : !Array.isArray(item.images) ? 'External Listing' : '-') : (item.adboosterIndustry ? item.adboosterIndustry.name : !Array.isArray(item.images) ? 'External Listing' : '-')}
                                                                        </div>
                                                                        <div
                                                                            className="text-xs text-gray-500">
                                                                            #{item.id}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                    <div
                                                                        className="text-sm font-medium whitespace-no-wrap">{item.clicks ? item.clicks : "-"}</div>
                                                                    <div
                                                                        className="text-xs text-gray-500 whitespace-no-wrap">Clicks
                                                                    </div>
                                                                </td>
                                                                <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                    <div
                                                                        className="text-sm font-medium whitespace-no-wrap">{item.leads ? item.leads : "-"}</div>
                                                                    <div
                                                                        className="text-xs text-gray-500 whitespace-no-wrap">Inquiries
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                })
                                            }
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div >
                }

                {
                    !this.state.loading &&
                    <div className="h-12 w-full"></div>
                }

            </div >
        )
    }
}

export default Dashboard;
