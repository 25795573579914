import React, { Component } from 'react';
import Dropdown from '../components/dropdown';
import { apiRegister } from "../services/apiRegister";
import { userRegister } from '../services/userRegister';
import queryString from "query-string";

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phone_codes: [
                { id: 45, name: '+45', value: 45 },
                { id: 33, name: '+33', value: 33 },
                { id: 49, name: '+49', value: 49 },
                { id: 353, name: '+353', value: 353 },
                { id: 47, name: '+47', value: 47 },
                { id: 46, name: '+46', value: 46 },
                { id: 41, name: '+41', value: 41 },
                { id: 44, name: '+44', value: 44 },
                { id: 61, name: '+61', value: 61 },
                { id: 1, name: '+1', value: 1 },
                { id: 91, name: '+91', value: 91 },
                { id: 972, name: '+972', value: 972 },
                { id: 64, name: '+64', value: 64 },
                { id: 974, name: '+974', value: 974 },
                { id: 966, name: '+966', value: 966 },
                { id: 27, name: '+27', value: 27 },
                { id: 971, name: '+971', value: 971 },
                { id: 34, name: '+34', value: 34 },
                { id: 351, name: '+351', value: 351 },
                { id: 31, name: '+31', value: 31 },
                { id: 39, name: '+39', value: 39 }
            ],
            phoneCode: { id: 971, name: "+971", value: 971 },
            phone: "",
            loading: false
        }
    };

    componentDidMount() {
        let query = {};
        try {
            query = queryString.parse(window.location.search);
        } catch (error) {
            query = {};
        }
        if (query.country) {
            this.state.phone_codes.map((item) => {
                if (item.value == query.country) {
                    this.setState({
                        phoneCode: item
                    })
                }
            })
        }
        if (query.number) {
            this.setState({
                phone: query.number
            })
        }
    }

    functions = {
        loginProptree: async () => {
            await this.promisedSetState({
                loading: true
            });
            try {
                await this.calls.loginProptree();
                if (window.location.origin.indexOf('localhost') !== -1) {
                    this.props.history.push("/verify-proptree");
                } else {
                    this.props.history.push("/verify");
                }
            } catch (error) {
                this.promisedSetState({
                    message: error.body ? error.body.message : "",
                    error: true,
                    loading: false
                });
            }
        }
    };

    calls = {
        loginProptree: () => {
            let options = apiRegister.options(null, 'POST', {
                phone: this.state.phone.toString(),
                phone_code: this.state.phoneCode ? this.state.phoneCode.value.toString() : null,
                proptree: true
            });
            let url = apiRegister.url.proptree + "/loginProptree";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <>
                <div style={{ height: "100vh", backgroundColor: "#F3F3F1" }} className="min-h-full background-pattern flex flex-col justify-center py-12 px-6">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md items-center">
                        <div className="w-full flex justify-center items-center">
                            <img
                                className="w-24 mb-6 sm:w-32 md:w-32"
                                src={require("../assets/images/Proptree_Logotype_RGB_Black.svg")}
                                alt="Workflow"
                            />
                        </div>
                        <div style={{ color: "#1E2330", fontWeight: 900, letterSpacing: "-0.02em", lineHeight: 1.05 }} className="text-5xl sm:max-w-130 text-center sm:text-2xl md:text-7xl">
                            Welcome back!
                        </div>
                    </div>
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

                        <div style={{ borderRadius: "20px" }} className="bg-white py-8 px-4 shadow sm:px-10">
                            <div className="space-y-6">
                                <div>
                                    <label style={{ fontSize: "1.1rem" }} htmlFor="email" className={(this.state.error ? "text-red-700" : "text-gray-700") + " block font-medium "}>
                                        WhatsApp
                                    </label>
                                    <div className="grid grid-cols-12 gap-2">
                                        <div style={{ paddingTop: "4px" }} className="col-span-4">
                                            <Dropdown
                                                proptreeLogin={true}
                                                selected={this.state.phoneCode}
                                                options={this.state.phone_codes}
                                                onChange={(item) => {
                                                    this.setState({
                                                        phoneCode: item
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-8">
                                            <div className="mt-1">
                                                <input
                                                    type="number"
                                                    onChange={(event) => {
                                                        this.setState({
                                                            phone: event.target.value
                                                        })
                                                    }}
                                                    disabled={this.state.loading}
                                                    value={this.state.phone}
                                                    required
                                                    style={{ fontSize: "1.1rem" }}
                                                    className={(this.state.error ? "border-red-300 focus:ring-red-500 focus:border-red-500" : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500") + " appearance-none block h-12 w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        onClick={() => {
                                            if (!this.state.loading) {
                                                this.functions.loginProptree();
                                            }
                                        }}
                                        style={{ fontSize: "1.1rem", backgroundColor: "#1E2330" }}
                                        className="w-full flex h-12 overflow-hidden rounded-full items-center text-white relative justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-offset-2"
                                    >
                                        {
                                            this.state.loading &&
                                            <div style={{ backgroundColor: "#1E2330" }} className="w-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-6 h-6 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                        Login
                                    </button>
                                </div>
                                {
                                    this.state.error &&
                                    <div className="flex items-center justify-center mt-3 font-medium text-sm text-red-600 hover:text-red-500">
                                        {this.state.message}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="w-full flex flex-row font-medium text-sm text-gray-600 justify-center items-center mt-4">
                            <a target="_blank" className="hover:text-gray-800" href="https://proptr.ee/policy/privacy">Privacy Policy</a>
                            <div className="ml-2 mr-2 text-xxs">|</div>
                            <a target="_blank" className="hover:text-gray-800" href="https://proptr.ee/policy/terms">Terms</a>
                            <div className="ml-2 mr-2 text-xxs">|</div>
                            <a target="_blank" className="hover:text-gray-800" href="https://proptr.ee/policy/use">Use Policy</a>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Login;
