import React, { Component, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, LockClosedIcon, SelectorIcon } from '@heroicons/react/solid'
import * as qr from "@bitjson/qr-code";

class QrCode extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: false,
            isLoading: true
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            message: this.props.message,
            color: this.props.color,
            bg_color: this.props.bg_color,
            is_dark: this.props.is_dark
        });
        qr.defineCustomElements(window);
        this.setState({ isLoading: false });
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            message: nextProps.message
        });
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        const { message, color, isLoading, bg_color, is_dark } = this.state;
        return (
            <div className="qr-code-container" style={{ width: "200px", height: "200px" }}>
                {
                    <div className="absolute skeleton-loader animate-pulse rounded-lg" style={{ width: "200px", height: "200px", zIndex: "0", backgroundColor: bg_color }}></div>
                }
                {
                    message && !isLoading &&
                    <qr-code
                        contents={message}
                        module-color={color}
                        position-ring-color={color}
                        position-center-color={color}
                        style={{ width: "100%", height: "100%" }}
                    >
                        <img
                            style={{
                                filter: this.props.is_dark ? "invert(100%)" : "none"
                            }}
                            src={this.props.enterprise.favicon}
                            slot="icon"
                        />
                    </qr-code>
                }
            </div>
        );
    }
}

export default QrCode;
