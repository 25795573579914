import React, { Component, Fragment } from 'react';
import { Transition, Menu } from '@headlessui/react';
import { MailIcon, ChartPieIcon, ChevronDownIcon, ExternalLinkIcon, MapIcon, MinusCircleIcon, PencilAltIcon, PhotographIcon, PlayIcon, PlusCircleIcon, RefreshIcon, TrashIcon, ThumbUpIcon, LoginIcon } from '@heroicons/react/outline';
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import { userRegister } from '../../services/userRegister';
import CreateUser from '../../components/createUser';
import WarningModal from '../../components/warningModal';
import Dropdown from '../../components/dropdown';
import CreateNewProptree from '../../components/createNewProptree';
import { UserAddIcon } from '@heroicons/react/solid';
import SelectionModal from '../../components/selectionModal';
import { versionRegister } from "../../services/versionRegister";
import { IdentificationIcon } from "@heroicons/react/outline/esm";
import { CheckCircleIcon } from "@heroicons/react/outline/esm/index";
var moment = require('moment');

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListProptrees extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            meta: {},
            open: false,
            tab: { id: 'agents', name: "Agents", value: 'agent', count: 0 },
            type: { id: 'all', name: "All types", value: 'all', count: 0 },
            status: { id: 'enabled', name: "Enabled", value: 'enabled' },
            feeds: [],
            user: {},
            sort: { id: 1, name: "Created", value: 'createdAt' },
            order: { id: 1, name: "Descending", value: 'descending' },
            page: 1,
            page_count: 10,
            total: 0,
            loading_feeds: false
        }
    };

    componentDidMount() {
        this.functions.getUser();
        this.functions.listProptrees(true);
    }

    componentWillUnmount() {

    }

    functions = {
        getUser: async () => {
            if (userRegister.get()) {
                await this.promisedSetState({
                    user: userRegister.get() ? userRegister.get() : {}
                });
            }
            let version = versionRegister.get();
            await this.promisedSetState({ version: version ? version : {} });
            if (this.state.user.syncing) {
                await this.promisedSetState({
                    loading_sync: true
                });
            }
        },
        listProptrees: async (init) => {
            try {
                await this.promisedSetState({
                    loading: init,
                    loading_feeds: !init
                });
                let response = await this.calls.listProptrees();
                await this.promisedSetState({
                    total: response.meta.total,
                    feeds: response.data,
                    meta: response.meta,
                    loading: false,
                    loading_feeds: false
                });
                this.functions.sideScroll();
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading: false,
                    loading_feeds: false
                })
            }
        },
        removeProptree: async () => {
            let selected_feed = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({
                remove: null,
                feeds: this.state.feeds.map((item) => {
                    if (item.id == selected_feed.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.removeProptree(selected_feed.id);
                await this.promisedSetState({
                    feeds: this.state.feeds.filter((item) => { return response.data.id != item.id }),
                    remove: null,
                    remove_name: "",
                });
                this.functions.listProptrees();
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == selected_feed.id) {
                            item.loading = false;
                        }
                        return item;
                    })
                })
            }
        },
        disablePropree: async () => {
            let selected_feed = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({
                remove: null,
                feeds: this.state.feeds.map((item) => {
                    if (item.id == selected_feed.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                await this.calls.disablePropree(selected_feed.id);
                await this.promisedSetState({
                    remove: null,
                    remove_name: "",
                });
                this.functions.listProptrees();
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == selected_feed.id) {
                            item.loading = false;
                        }
                        return item;
                    })
                })
            }
        },
        enableProptree: async (agent) => {
            let selected_feed = JSON.parse(JSON.stringify(agent));
            await this.promisedSetState({
                remove: null,
                feeds: this.state.feeds.map((item) => {
                    if (item.id == selected_feed.id) {
                        item.loading = true;
                    }
                    return item;
                })
            });
            try {
                await this.calls.enableProptree(selected_feed.id);
                await this.promisedSetState({
                    remove: null,
                    remove_name: "",
                });
                this.functions.listProptrees();
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    feeds: this.state.feeds.map((item) => {
                        if (item.id == selected_feed.id) {
                            item.loading = false;
                        }
                        return item;
                    })
                })
            }
        },
        createProptreeAgent: async (agent) => {
            let selected_feed = JSON.parse(JSON.stringify(agent));
            await this.promisedSetState({
                feeds: this.state.feeds.map((item) => {
                    if (item.agentId == selected_feed.agentId) {
                        item.loading_proptree = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.createProptreeAgent({ id: agent.agentId });
                this.state.feeds = this.state.feeds.map((item) => {
                    if (item.agentId == agent.agentId) {
                        item.id = response.data.id;
                        item.shortId = response.data.shortId;
                        item.subscription = response.data.subscription;
                        item.createdAt = response.data.createdAt;
                        item.type = response.data.type;
                        item.whatsAppCountryCode = response.data.whatsAppCountryCode;
                        item.whatsAppNumber = response.data.whatsAppNumber;
                        item.proptreeWelcomeWaStatus = response.data.proptreeWelcomeWaStatus;
                    }
                    return item;
                });
                await this.promisedSetState({
                    feeds: this.state.feeds
                });
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong"
                })
            }
            await this.promisedSetState({
                feeds: this.state.feeds.map((item) => {
                    if (item.agentId == selected_feed.agentId) {
                        item.loading_proptree = false;
                    }
                    return item;
                })
            });
        },
        sendProptreeWelcomeTemplate: async (temp_agent) => {
            let agent = JSON.parse(JSON.stringify(temp_agent));
            await this.promisedSetState({
                template_modal: null,
                feeds: this.state.feeds.map((item) => {
                    if (item.id == agent.id) {
                        item.loading_proptree = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.sendProptreeWelcomeTemplate({ id: agent.id });
                this.state.feeds = this.state.feeds.map((item) => {
                    if (item.id == agent.id) {
                        item.proptreeWelcomeWaID = response.data.proptreeWelcomeWaID;
                        item.proptreeWelcomeWaStatus = response.data.proptreeWelcomeWaStatus;
                    }
                    return item;
                });
                await this.promisedSetState({
                    feeds: this.state.feeds
                });
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong"
                })
            }
            await this.promisedSetState({
                feeds: this.state.feeds.map((item) => {
                    if (item.id == agent.id) {
                        item.loading_proptree = false;
                    }
                    return item;
                })
            });
        },
        loginAsProptree: async (agent) => {
            await this.promisedSetState({
                feeds: this.state.feeds.map((item) => {
                    if (item.id == agent.id) {
                        item.loading_proptree = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.loginAsProptree({ id: agent.id });
                if (response.data.token) {
                    if (window.location.hostname === 'localhost') {
                        window.open("http://localhost:5001/login-proptree/" + response.data.token);
                    } else {
                        window.open("https://app.proptr.ee/login/" + response.data.token);
                    }
                }
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong"
                })
            }
            await this.promisedSetState({
                feeds: this.state.feeds.map((item) => {
                    if (item.id == agent.id) {
                        item.loading_proptree = false;
                    }
                    return item;
                })
            });
        },
        sideScroll: () => {
            try {
                const slider = document.querySelector('#table_container');
                let isDown = false;
                let startX;
                let scrollLeft;
                slider.addEventListener('mousedown', (e) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mouseup', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 1;
                    slider.scrollLeft = scrollLeft - walk;
                });
            } catch (error) { }
        }
    };

    calls = {
        listProptrees: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/proptree/listProptrees?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&type=" + this.state.tab.value + "&status=" + this.state.status.value + "&subscription=" + this.state.type.value + "&version=" + this.state.version.id;
            return apiRegister.call(options, url);
        },
        disablePropree: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', null);
            let url = apiRegister.url.api + "/proptree/disableProptree/" + id;
            return apiRegister.call(options, url);
        },
        enableProptree: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', null);
            let url = apiRegister.url.api + "/proptree/enableProptree/" + id;
            return apiRegister.call(options, url);
        },
        removeProptree: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/proptree/removeProptree/" + id;
            return apiRegister.call(options, url);
        },
        createProptreeAgent: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/proptree/createProptreeAgent";
            return apiRegister.call(options, url);
        },
        sendProptreeWelcomeTemplate: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/proptree/sendProptreeWelcomeTemplate/" + data.id;
            return apiRegister.call(options, url);
        },
        loginAsProptree: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/proptree/loginAsProptree/" + data.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        basicUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.basicSwitch && this.state.user.enterprise.basicSwitch.value == 'enabled';
        },
        creativeUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.creativeSwitch && this.state.user.enterprise.creativeSwitch.value == 'enabled';
        },
        proptreeUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.proptreeSwitch && this.state.user.enterprise.proptreeSwitch.value == 'enabled';
        },
        fromAdmin: () => {
            return this.state.user && this.state.user.from_admin;
        },
        showLeads: () => {
            return !(this.state.user.enterprise && this.state.user.enterprise.managerUIleads && this.state.user.enterprise.managerUIleads.value == 'disabled');
        },
        enterpriseType: (enterprise) => {
            let string = '';
            try {
                if (enterprise.version) {
                    string += '<span class="text-sm text-indigo-500 uppercase">' + enterprise.version.name + ' • </span>';
                }
                if (enterprise.legacy && enterprise.legacy.value == "enabled") {
                    string += '<span class="text-sm text-pink-500 uppercase">SCALE</span>';
                    if (enterprise.parserType.value !== "" && enterprise.parserType.value.indexOf("project") !== -1) {
                        string += '<span class="text-sm text-pink-500 uppercase"> PROJECTS</span>';
                    }
                    if (enterprise.parserType.value !== "" && enterprise.parserType.value.indexOf("project") === -1) {
                        string += '<span class="text-sm text-pink-500 uppercase"> UNITS</span>';
                    }
                    if (enterprise.basicSwitch && enterprise.basicSwitch.value == "enabled") {
                        string += '<span class="text-sm text-orange-500 uppercase"> • BASIC</span>';
                    }
                    if (enterprise.creativeSwitch && enterprise.creativeSwitch.value == "enabled") {
                        string += '<span class="text-sm text-yellow-600 uppercase"> • CREATIVE</span>';
                    }
                    if (enterprise.proptreeSwitch && enterprise.proptreeSwitch.value == "enabled") {
                        string += '<span class="text-sm text-purple-500 uppercase"> • PROPTREE</span>';
                    }
                } else {
                    string += '<span class="text-sm text-indigo-500 uppercase">LEGACY</span>';
                }
            } catch (error) { }
            return string;
        },
        openProptree: (agent) => {
            let user_name = agent.shortId;
            if (agent.alias && agent.alias !== "") {
                user_name = agent.alias;
            }
            if (window.location.origin.indexOf('localhost') !== -1) {
                window.open("http://localhost:5001/" + user_name + "/proptree", "_blank");
            } else {
                window.open("https://proptr.ee/" + user_name + "/", "_blank");
            }
        },
        openProptreeId: (agent) => {
            let user_name = agent.shortId;
            if (window.location.origin.indexOf('localhost') !== -1) {
                window.open("http://localhost:5001/" + user_name + "/proptree", "_blank");
            } else {
                window.open("https://proptr.ee/" + user_name + "/", "_blank");
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <>

                {/*ADMIN HEADER*/}
                {
                    this.props.admin &&
                    <div className="bg-white overflow-hidden shadow">
                        <div className="bg-white p-6">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5 justify-center flex flex-col sm:flex-row items-center sm:items-start">
                                    <div className="rounded-full mb-3 sm:mb-0 h-20 w-20 border overflow-hidden justify-center flex items-center bg-indigo-100 border-indigo-500">
                                        <div>
                                            <IdentificationIcon className="h-8 w-8 text-indigo-500" aria-hidden="true" />
                                        </div>
                                    </div>
                                    <div className="text-center sm:mt-0 sm:text-left pt-0 sm:pt-2">
                                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">Proptree</p>
                                        <p className="text-sm font-medium text-gray-600">admin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className={(this.props.admin ? "px-4 sm:px-8 py-12" : "sm:p-0 p-6 pt-0 bg-gray-50 ") + " grid grid-cols-12 h-full min-h-full"}>

                    {/*LOADING*/}
                    {
                        this.state.loading &&
                        <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                            <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                                <div style={{ borderTopColor: "transparent" }}
                                    class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                <div className="font-semibold text-sm mt-4">Loading proptree ...</div>
                            </div>
                        </div>
                    }

                    {/*WELCOME WARNING*/}
                    <SelectionModal
                        proptree={true}
                        open={this.state.template_modal ? true : false}
                        onAccept={() => {
                            this.functions.sendProptreeWelcomeTemplate(this.state.template_modal);
                        }}
                        onClose={() => {
                            this.setState({
                                template_modal: false
                            })
                        }}
                    />

                    {/*REMOVE MODAL*/}
                    <WarningModal
                        open={this.state.remove ? true : false}
                        title={this.state.remove && this.state.remove.disabled ? "Delete Feed" : "Disable Proptree"}
                        description={"Are you sure you want to " + (this.state.remove && this.state.remove.disabled ? "delete" : "disable") + " this proptree? This action cannot be undone"}
                        cancelButtonText={"cancel"}
                        submitButtonText={this.state.remove && this.state.remove.disabled ? "delete" : "disable"}
                        disableSubmitButton={this.state.remove && this.state.remove_name != this.state.remove.shortId}
                        showInput={true}
                        copyInput={this.state.remove ? this.state.remove.shortId : ""}
                        inputTitle={'Type "Name"'}
                        inputError={(this.state.remove && this.state.remove_name && this.state.remove_name !== "" && this.state.remove_name != this.state.remove.shortId) ? "Names not matching" : false}
                        onClose={(value) => {
                            this.setState({ remove: null })
                        }}
                        onSubmit={(value) => {
                            if (this.state.remove && this.state.remove_name == this.state.remove.shortId) {
                                if (this.state.remove.status == "disabled") {
                                    this.functions.removeProptree();
                                } else {
                                    this.functions.disablePropree();
                                }
                            }
                        }}
                        onInput={(value) => {
                            this.setState({ remove_name: value })
                        }}
                    />

                    {/*ERROR MODAL*/}
                    <WarningModal
                        open={this.state.sync_error ? true : false}
                        softWarning={true}
                        title={"Too many sync attempts"}
                        description={"Please wait 10 min"}
                        cancelButtonText={"ok"}
                        noSubmitButton={true}
                        onClose={() => {
                            this.setState({
                                sync_error: null
                            })
                        }}
                    />

                    {/*ERROR MESSAGE*/}
                    <WarningModal
                        open={this.state.error ? true : false}
                        softWarning={false}
                        title={this.state.message}
                        description={"Error message"}
                        cancelButtonText={"ok"}
                        noSubmitButton={true}
                        onClose={() => {
                            this.setState({
                                error: null
                            })
                        }}
                    />

                    {/*CREATE FEED*/}
                    <CreateNewProptree
                        enterprise={this.state.user.enterprise}
                        proptree={this.state.selected}
                        open={this.state.open}
                        toggle={() => {
                            this.setState({
                                open: !this.state.open
                            })
                        }}
                        onCreate={() => {
                            this.setState({
                                open: !this.state.open
                            });
                            this.functions.listProptrees();
                        }}
                        onUpdate={() => {
                            this.setState({
                                open: !this.state.open
                            });
                            this.functions.listProptrees();
                        }}
                    />

                    {/*OPTION*/}
                    {
                        !this.state.loading &&
                        <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pr-2">
                            <div className={this.props.admin ? "bg-white rounded-md" : "bg-white border rounded-md"}>
                                <Dropdown
                                    locked={this.state.loading_sync}
                                    selected={this.state.tab}
                                    options={[
                                        { id: 'agents', name: "Agents", value: 'agent', count: this.state.meta.agents },
                                        { id: 'agency', name: "Agency", value: 'agency', count: this.state.meta.agency },
                                        { id: 'custom', name: "Affiliates", value: 'custom', count: this.state.meta.custom },
                                    ]}
                                    searchInput={false}
                                    onChange={async (option) => {
                                        await this.promisedSetState({
                                            tab: option,
                                            page: 1
                                        });
                                        this.functions.listProptrees();
                                    }}
                                />
                            </div>
                        </div>
                    }

                    {/*STATUS*/}
                    {
                        !this.state.loading &&
                        <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2">
                            <div className={this.props.admin ? "bg-white rounded-md" : "bg-white border rounded-md"}>
                                <Dropdown
                                    locked={this.state.loading_sync}
                                    selected={this.state.status}
                                    options={[
                                        { id: 'enabled', name: "Enabled", value: 'enabled', count: this.state.meta.enabled },
                                        { id: 'disabled', name: "Disabled", value: 'disabled', count: this.state.meta.disabled }
                                    ]}
                                    searchInput={false}
                                    onChange={async (option) => {
                                        await this.promisedSetState({
                                            status: option,
                                            page: 1
                                        });
                                        this.functions.listProptrees();
                                    }}
                                />
                            </div>
                        </div>
                    }

                    <div className="col-span-0 hidden md:block md:col-span-4"></div>

                    {/*SORT*/}
                    {
                        !this.state.loading &&
                        <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pr-2 md:pr-2">
                            <div className={this.props.admin ? "bg-white rounded-md" : "bg-white border rounded-md"}>
                                <Dropdown
                                    locked={this.state.loading_sync}
                                    selected={this.state.sort}
                                    options={[
                                        { id: 1, name: "Created", value: 'createdAt' }
                                    ]}
                                    searchInput={false}
                                    onChange={async (option) => {
                                        await this.promisedSetState({
                                            sort: option,
                                            page: 1
                                        });
                                        this.functions.listProptrees();
                                    }}
                                />
                            </div>
                        </div>
                    }

                    {/*ORDER*/}
                    {
                        !this.state.loading &&
                        <div className="col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2">
                            <div className={this.props.admin ? "bg-white rounded-md" : "bg-white border rounded-md"}>
                                <Dropdown
                                    locked={this.state.loading_sync}
                                    selected={this.state.order}
                                    options={[
                                        { id: 1, name: "Descending", value: 'descending' },
                                        { id: 2, name: "Ascending", value: 'ascending' }
                                    ]}
                                    searchInput={false}
                                    onChange={async (option) => {
                                        await this.promisedSetState({
                                            order: option,
                                            page: 1
                                        });
                                        this.functions.listProptrees();
                                    }}
                                />
                            </div>
                        </div>
                    }

                    {/*PROPTREE*/}
                    {
                        !this.state.loading &&
                        <div className="col-span-12">
                            <div className="">
                                {
                                    <section className={(this.state.loading_sync ? "overflow-hidden" : "") + (this.props.admin ? " bg-white shadow sm:rounded-md" : " bg-white shadow-lg border rounded-lg")}>
                                        <div className="flex p-4 lg:px-6 border-b border-gray-100 items-center">
                                            <div className="font-sm font-semibold flex-1 flex">
                                                {this.state.tab.name}
                                            </div>
                                            {
                                                this.state.status.value == "enabled" &&
                                                this.state.tab.value == "custom" &&
                                                this.state.user.enterprise && this.state.user.enterprise.limitProptreeAmount && this.state.user.enterprise.limitProptreeAmount.value <= this.state.meta.custom &&
                                                !this.state.loading_sync &&
                                                <div className="rounded-md text-red-600 font-medium text-right text-xxs py-2">
                                                    <div>Upgrade or disable</div>proptree {this.state.user.enterprise && this.state.user.enterprise.limitProptreeAmount && this.state.user.enterprise.limitProptreeAmount.value ? "[" + this.state.user.enterprise.limitProptreeAmount.value + "/" + this.state.meta.custom + " created]" : "[-/- created]"}
                                                </div>
                                            }
                                            {
                                                this.state.user.enterprise && this.state.user.enterprise.limitProptreeAmount && this.state.user.enterprise.limitProptreeAmount.value > this.state.meta.custom &&
                                                this.state.user.access && this.state.user.access.id === 1 &&
                                                <div className="relative">
                                                    <div onClick={() => {
                                                        this.setState({
                                                            open: true,
                                                            selected: null
                                                        })
                                                    }} className={(this.renders.basicUI() ? "text-white bg-purple-500 shadow hover:bg-purple-600 " : "text-purple-500 bg-purple-100 hover:text-purple-700 ") + " text-sm font-medium cursor-pointer h-10  px-4 ml-4 rounded-md  border-purple-500 items-center flex flex-row "}>
                                                        <PlusCircleIcon className="w-5 h-5 mr-2" />
                                                        Create {this.state.user.enterprise && this.state.user.enterprise.limitProptreeAmount && this.state.user.enterprise.limitProptreeAmount.value ? "[" + (this.state.user.enterprise.limitProptreeAmount.value - this.state.meta.custom) + " left]" : "[- left]"}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {
                                            this.state.feeds.length < 1 &&
                                            <div className="h-110 flex items-center justify-center border-b border-gray-100 relative">
                                                {
                                                    !this.state.loading_feeds &&
                                                    <div className="font-medium text-sm">No <span className="text-purple-500">Proptree</span> available</div>
                                                }
                                                {
                                                    this.state.loading_feeds &&
                                                    <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            this.state.feeds.length > 0 &&
                                            <div id={"table_container"} className={"relative min-h-110 overflow-auto"}>
                                                <table className="min-w-full">
                                                    <thead className="">
                                                        <tr>
                                                            <th scope="col" className="">
                                                                <span className="sr-only">Listings</span>
                                                            </th>
                                                            <th scope="col" className="">
                                                                <span className="sr-only">Channel</span>
                                                            </th>
                                                            <th scope="col" className="">
                                                                <span className="sr-only">Theme</span>
                                                            </th>
                                                            <th scope="col" className="">
                                                                <span className="sr-only">Type</span>
                                                            </th>
                                                            <th scope="col" className="">
                                                                <span className="sr-only">Language</span>
                                                            </th>
                                                            <th scope="col" className="">
                                                                <span className="sr-only">Currency</span>
                                                            </th>
                                                            <th scope="col" className="">
                                                                <span className="sr-only">Property</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="">
                                                        {
                                                            this.state.feeds.map((agent, index) => {
                                                                return (
                                                                    <Fragment>
                                                                        <tr key={agent.email} className="border-b border-gray-100">
                                                                            <td className="text-left pl-6">
                                                                                <div className="flex flex-row">
                                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                                        <div>
                                                                                            <Menu.Button className="inline-flex relative items-center hover:bg-gray-50 justify-center rounded-md bg-white border w-10 h-10 text-sm font-medium text-gray-900">
                                                                                                {
                                                                                                    !agent.loading &&
                                                                                                    !agent.loading_proptree &&
                                                                                                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                                }
                                                                                                {
                                                                                                    (agent.loading || agent.loading_proptree) &&
                                                                                                    <div className="top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-red-50 bg-opacity-50">
                                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                                    </div>
                                                                                                }
                                                                                            </Menu.Button>
                                                                                        </div>
                                                                                        <Transition
                                                                                            as={Fragment}
                                                                                            enter="transition ease-out duration-100"
                                                                                            enterFrom="transform opacity-0 scale-95"
                                                                                            enterTo="transform opacity-100 scale-100"
                                                                                            leave="transition ease-in duration-75"
                                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                                            leaveTo="transform opacity-0 scale-95"
                                                                                        >
                                                                                            <Menu.Items className="absolute border left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg" style={{ zIndex: "9999" }}>
                                                                                                <div className="py-1">
                                                                                                    {
                                                                                                        [
                                                                                                            { name: "Edit", value: "edit", icon: PencilAltIcon },
                                                                                                            { name: "Enable", value: "enable", icon: CheckCircleIcon },
                                                                                                            { name: "Create proptree", value: "create_proptree", icon: UserAddIcon },
                                                                                                            { name: "Open proptree", value: "open_proptree", icon: ExternalLinkIcon },
                                                                                                            { name: "Login as proptree", value: "login_proptree", icon: LoginIcon },
                                                                                                            { name: "Send welcome template", value: "welcome_template", icon: MailIcon },
                                                                                                            { name: "Disable", value: "disable", icon: MinusCircleIcon },
                                                                                                            { name: "Remove", value: "remove", icon: TrashIcon },
                                                                                                        ].filter((item) => {
                                                                                                            if (item.value == "login_proptree") {
                                                                                                                return agent.shortId;
                                                                                                            } else if (item.value == "create_proptree") {
                                                                                                                return !agent.shortId;
                                                                                                            } if (item.value == "open_proptree") {
                                                                                                                return agent.shortId;
                                                                                                            } if (item.value == "welcome_template") {
                                                                                                                return !agent.proptreeWelcomeWaStatus && agent.shortId && this.state.status.value == "enabled";
                                                                                                            } else {
                                                                                                                return true;
                                                                                                            }
                                                                                                        }).filter((item) => {
                                                                                                            if (item.value == "edit") {
                                                                                                                return this.state.status.value == "enabled" && agent.type !== 'agent' && agent.shortId
                                                                                                            } else if (item.value == "remove" || item.value == "enable") {
                                                                                                                return agent.status == "disabled" && agent.shortId
                                                                                                            } else if (item.value == "disable") {
                                                                                                                return agent.status == "enabled" && agent.shortId
                                                                                                            } else {
                                                                                                                return true;
                                                                                                            }
                                                                                                        }).map((item) => {
                                                                                                            return (
                                                                                                                <Menu.Item>
                                                                                                                    {({ active }) => (
                                                                                                                        <div
                                                                                                                            onClick={() => {
                                                                                                                                if (item.value == "edit") {
                                                                                                                                    this.setState({
                                                                                                                                        open: true,
                                                                                                                                        selected: agent
                                                                                                                                    })
                                                                                                                                } else if (item.value == "enable") {
                                                                                                                                    this.functions.enableProptree(agent);
                                                                                                                                } else if (item.value == "disable" || item.value == "remove") {
                                                                                                                                    this.setState({
                                                                                                                                        remove: agent
                                                                                                                                    })
                                                                                                                                } else if (item.value == "login_proptree") {
                                                                                                                                    if (!agent.loading_proptree) {
                                                                                                                                        this.functions.loginAsProptree(agent);
                                                                                                                                    }
                                                                                                                                } else if (item.value == "create_proptree") {
                                                                                                                                    if (!agent.loading_proptree) {
                                                                                                                                        this.functions.createProptreeAgent(agent);
                                                                                                                                    }
                                                                                                                                } else if (item.value == "open_proptree") {

                                                                                                                                    this.renders.openProptree(agent);

                                                                                                                                } else if (item.value == "welcome_template") {
                                                                                                                                    this.setState({
                                                                                                                                        template_modal: agent
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            }}
                                                                                                                            className={classNames(
                                                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                'flex items-center px-4 py-2 font-medium text-sm cursor-pointer',
                                                                                                                                (item.value == "disable" || item.value == "remove") ? "text-red-500 bg-red-100" : ""
                                                                                                                            )}
                                                                                                                        >
                                                                                                                            <div className="flex flex-1">{item.name}</div>
                                                                                                                            <item.icon className="w-5" />
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </Menu.Item>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </div>
                                                                                            </Menu.Items>
                                                                                        </Transition>
                                                                                    </Menu>
                                                                                </div>
                                                                            </td>
                                                                            {
                                                                                this.props.admin &&
                                                                                <td className="px-6 py-4 text-left whitespace-no-wrap">
                                                                                    <div className="flex items-center">
                                                                                        {
                                                                                            !(agent.enterprise && agent.enterprise.logo) &&
                                                                                            <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                                                                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                                                </svg>
                                                                                            </span>
                                                                                        }
                                                                                        {
                                                                                            (agent.enterprise && agent.enterprise.logo) &&
                                                                                            <div className="h-10 min-w-10 rounded-full overflow-hidden flex items-center justify-center border">
                                                                                                <img className="w-10" src={agent.enterprise.logo} alt="" />
                                                                                            </div>
                                                                                        }
                                                                                        <div className="ml-4">
                                                                                            <div className="text-sm font-medium text-gray-900 capitalize">{agent.enterprise.name}</div>
                                                                                            <div className="text-sm text-gray-500">{agent.enterprise.companyName}</div><div dangerouslySetInnerHTML={{ __html: this.renders.enterpriseType(agent.enterprise) }}></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            }
                                                                            <td className="px-6 py-4 text-left">
                                                                                <div className="flex flex-row">
                                                                                    {
                                                                                        !agent.profileImage &&
                                                                                        <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                                                                                            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        agent.profileImage &&
                                                                                        <div className="flex-shrink-0 h-10 w-10">
                                                                                            <img className="h-10 w-10 rounded-full" src={agent.profileImage} alt="" />
                                                                                        </div>
                                                                                    }
                                                                                    <div className="ml-4 whitespace-no-wrap">
                                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.profileFirstName}</div>
                                                                                        {
                                                                                            agent.shortId &&
                                                                                            agent.whatsAppNumber &&
                                                                                            <div className="text-sm text-gray-500 whitespace-no-wrap">{agent.whatsAppCountryCode ? ("+" + agent.whatsAppCountryCode.value) : ""}{agent.whatsAppNumber}</div>
                                                                                        }
                                                                                        {
                                                                                            agent.shortId &&
                                                                                            !agent.whatsAppNumber &&
                                                                                            <div className="text-sm text-gray-500 whitespace-no-wrap">No external access</div>
                                                                                        }
                                                                                        {
                                                                                            !agent.shortId &&
                                                                                            <div className="text-sm text-gray-500 whitespace-no-wrap">{agent.phone}</div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center px-6 py-4">
                                                                                <div className={"text-sm capitalize"}>
                                                                                    {agent.proptreeWelcomeWaStatus ? agent.proptreeWelcomeWaStatus : "-"}
                                                                                </div>
                                                                                <div className="text-sm whitespace-no-wrap text-gray-500">{"Welcome template"}</div>
                                                                            </td>
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                {
                                                                                    agent.shortId &&
                                                                                    <div onClick={() => {
                                                                                        this.renders.openProptreeId(agent)
                                                                                    }} target="_blank" className="text-sm text-gray-900 cursor-pointer whitespace-no-wrap">
                                                                                        {agent.shortId}
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    !agent.shortId &&
                                                                                    <div className="text-red-500 p-1 px-2 text-xs rounded-md bg-red-100 whitespace-no-wrap">
                                                                                        {"Not created"}
                                                                                    </div>
                                                                                }
                                                                                <div className="text-sm whitespace-no-wrap text-gray-500 mb-1">{"Proptree"}</div>
                                                                            </td>
                                                                            {
                                                                                <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                    <div><div className="capitalize text-gray-900 p-1 px-2 inline-flex text-xs rounded-md bg-gray-100 whitespace-no-wrap">{agent.type ? agent.agencyDefault ? "Agency" : agent.type : agent.agentId ? "agent" : "custom"}</div></div>
                                                                                    <div className="text-sm text-gray-500 mb-1">Type</div>
                                                                                </td>
                                                                            }
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                {
                                                                                    !agent.alias &&
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{"-"}</div>
                                                                                }
                                                                                {
                                                                                    agent.alias && agent.alias !== "" &&
                                                                                    <div onClick={() => {
                                                                                        this.renders.openProptree(agent)
                                                                                    }} target="_blank" className="text-sm text-gray-900 cursor-pointer whitespace-no-wrap">
                                                                                        {agent.alias}
                                                                                    </div>
                                                                                }
                                                                                <div className="text-sm text-gray-500">Custom shortname</div>
                                                                            </td>
                                                                            {
                                                                                false &&
                                                                                <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                    <div className="inline-flex"><div className=" text-gray-900 p-1 px-2 text-xs capitalize rounded-md bg-gray-100 whitespace-no-wrap">{agent.type && agent.type.toLowerCase() === "custom" ? "Affiliate" : "Agent"}</div></div>
                                                                                    <div className="text-sm text-gray-500 mb-1">Type</div>
                                                                                </td>
                                                                            }
                                                                            {
                                                                                false &&
                                                                                <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                    <div className="inline-flex">
                                                                                        {
                                                                                            agent.shortId &&
                                                                                            <div className=" text-gray-900 p-1 px-2 text-xs capitalize rounded-md bg-gray-100 whitespace-no-wrap">{agent.type && agent.type.toLowerCase() === "custom" ? "Propree Plus" : (agent.shortId ? (agent.subscription ? "Propree Plus" : "Propree Basic") : "None")}</div>
                                                                                        }
                                                                                        {
                                                                                            !agent.shortId &&
                                                                                            "-"
                                                                                        }
                                                                                    </div>
                                                                                    <div className="text-sm text-gray-500 mb-1">Subscription</div>
                                                                                </td>
                                                                            }
                                                                            {
                                                                                <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.listingsCount ? agent.listingsCount : "0"}</div>
                                                                                    <div className="text-sm text-gray-500">Active listings</div>
                                                                                </td>
                                                                            }
                                                                            {
                                                                                <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.pageLoadStats && agent.pageLoadStats.month && agent.pageLoadStats.month[moment().format("YYYY-MM")] ? agent.pageLoadStats.month[moment().format("YYYY-MM")] : "0"}</div>
                                                                                    <div className="text-sm text-gray-500">Views</div>
                                                                                </td>
                                                                            }
                                                                            {
                                                                                <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.listingClicksStats && agent.listingClicksStats.total && agent.listingClicksStats.total.month && agent.listingClicksStats.total.month[moment().format("YYYY-MM")] ? agent.listingClicksStats.total.month[moment().format("YYYY-MM")] : "0"}</div>
                                                                                    <div className="text-sm text-gray-500">Clicks</div>
                                                                                </td>
                                                                            }
                                                                            {
                                                                                !this.props.admin && !this.renders.basicUI() && this.renders.showLeads() &&
                                                                                <td className="text-center px-6 py-4">
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.shortId && agent.leads ? agent.leads : "0"}</div>
                                                                                    <div className="text-sm text-gray-500">Leads</div>
                                                                                </td>
                                                                            }
                                                                            {
                                                                                !this.props.admin && this.renders.basicUI() && this.renders.proptreeUI() && this.renders.showLeads() &&
                                                                                <td className="text-center px-6 py-4">
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.shortId && agent.leads ? agent.leads : "0"}</div>
                                                                                    <div className="text-sm text-gray-500">Inquiries</div>
                                                                                </td>
                                                                            }
                                                                            {
                                                                                <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{agent.shortId && moment(agent.createdAt).format("YYYY-MM-DD") ? moment(agent.createdAt).format("YYYY-MM-DD") : '-'}</div>
                                                                                    <div className="text-sm text-gray-500">Created</div>
                                                                                </td>
                                                                            }
                                                                        </tr>
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                {
                                                    this.state.loading_feeds &&
                                                    <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            this.state.feeds.length > 0 &&
                                            <div className="rounded-b-lg relative">
                                                <nav
                                                    className="py-4 flex items-center justify-between px-6"
                                                    aria-label="Pagination"
                                                >
                                                    {
                                                        this.state.feeds.length > 0 &&
                                                        <div className="hidden sm:block">
                                                            <p className="text-sm text-gray-700">
                                                                Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state.total ? this.state.total : (this.state.page * this.state.page_count)}</span> of{' '}
                                                                <span className="font-medium">{this.state.total}</span> results
                                                            </p>
                                                        </div>
                                                    }
                                                    <div className="flex-1 flex flex-row">
                                                        <div className="flex-1 sm:justify-end flex">
                                                            <Menu as="div" className="relative inline-block text-left mr-2">
                                                                <div>
                                                                    <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                        Page limit: {this.state.page_count}
                                                                        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                    </Menu.Button>
                                                                </div>
                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                        <div className="py-1">
                                                                            {
                                                                                [1, 10, 20, 30, 50, 100, 200, 300, 400].map((item) => {
                                                                                    return (
                                                                                        <Menu.Item>
                                                                                            {({ active }) => (
                                                                                                <div
                                                                                                    onClick={() => {
                                                                                                        this.setState({
                                                                                                            page_count: item,
                                                                                                            page: 1
                                                                                                        }, () => {
                                                                                                            this.functions.listProptrees();
                                                                                                        })
                                                                                                    }}
                                                                                                    className={classNames(
                                                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                        'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                        this.state.page_count == item ? "text-purple-500" : ""
                                                                                                    )}
                                                                                                >
                                                                                                    {item}
                                                                                                </div>
                                                                                            )}
                                                                                        </Menu.Item>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </Menu>
                                                        </div>
                                                        <div
                                                            onClick={() => {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                                }, () => {
                                                                    this.functions.listProptrees();
                                                                });
                                                            }}
                                                            className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                        >
                                                            Previous
                                                        </div>
                                                        <div
                                                            onClick={() => {
                                                                if (((this.state.page) * this.state.page_count) <= this.state.total) {
                                                                    this.setState({
                                                                        select_all: false,
                                                                        page: this.state.page + 1
                                                                    }, () => {
                                                                        this.functions.listProptrees();
                                                                    });
                                                                }
                                                            }}
                                                            className={(((this.state.page) * this.state.page_count) > this.state.total ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                        >
                                                            Next
                                                        </div>
                                                    </div>
                                                </nav>
                                            </div>
                                        }
                                    </section>
                                }
                            </div>
                        </div>
                    }

                    {
                        <div className="w-full h-10 hidden sm:block"></div>
                    }

                </div>
            </>
        )
    }
}

export default ListProptrees;