import React, { Component, Fragment } from 'react';
import { DownloadIcon, ExternalLinkIcon, PlusIcon, SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/solid'
import { Dialog, Transition, Menu } from '@headlessui/react'
import { UsersIcon, PhoneIcon, ChevronDownIcon, PlusCircleIcon, UserIcon, TrashIcon, MinusCircleIcon, PencilAltIcon, StarIcon } from '@heroicons/react/outline'
import { apiRegister } from "../../services/apiRegister";
import { tokenRegister } from '../../services/tokenRegister';
import moment from 'moment';
import { userRegister } from '../../services/userRegister';
import { Bar } from 'react-chartjs-2';
import Dropdown from '../../components/dropdown';
import SingleSelection from '../../components/singleSelection';
import WarningModal from '../../components/warningModal';
import CreateClient from '../../components/createClient';
import CreateLeadNew from '../../components/createLeadNew';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class ListClients extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            open: false,
            open_lead: false,
            client_lead: {},
            tab: { id: 2, name: "All qualified", value: 'relevant', count: 0 },
            ranking: { id: 1, name: "All ratings", value: 'all', count: 0 },
            follow_up: { id: 1, name: "All follow-ups", value: 'all', count: 0 },
            clients: [],
            user: {},
            sort: { id: 6, name: "Latest lead", value: 'latestLead' },
            order: { id: 1, name: "Descending", value: 'descending' },
            page: 1,
            page_count: 10,
            total: 0
        }
    };

    componentDidMount() {
        this.functions.getUser();
    }

    functions = {
        getUser: async () => {
            if (userRegister.get()) {
                let account = userRegister.get();
                if (account.access && account.access.id === 1) {
                    account.manager = true;
                }
                await this.promisedSetState({
                    user: account
                });
                if (this.renders.basicUI()) {
                    await this.promisedSetState({
                        sort: { id: 6, name: "Latest inquiry", value: 'latestLead' }
                    });
                }
                if (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") {
                    await this.promisedSetState({
                        tab: { id: 1, name: "All qualifications", value: 'total', count: this.state.total }
                    });
                }
            }
            this.functions.getClients();
        },
        getClients: async () => {
            try {
                await this.promisedSetState({ loading_users: true });
                let response = await this.calls.getClients();
                await this.promisedSetState({

                    clients: response.data,
                    total: response.meta.total ? response.meta.total : 0,

                    //QUALIFICATONS
                    'relevant': response.meta['relevant'] ? response.meta['relevant'] : 0,
                    'double-qualified': response.meta['double-qualified'] ? response.meta['double-qualified'] : 0,
                    'qualified': response.meta['qualified'] ? response.meta['qualified'] : 0,
                    'pre-qualified': response.meta['pre-qualified'] ? response.meta['pre-qualified'] : 0,
                    'unqualified': response.meta['unqualified'] ? response.meta['unqualified'] : 0,

                    //RATING
                    "all_ratings": response.meta ? response.meta['all_ratings'] : 0,
                    "signed_deal": response.meta ? response.meta['signed_deal'] : 0,
                    "high": response.meta ? response.meta['high'] : 0,
                    "medium": response.meta ? response.meta['medium'] : 0,
                    "low": response.meta ? response.meta['low'] : 0,
                    "block": response.meta ? response.meta['block'] : 0,
                    "unrated": response.meta ? response.meta['unrated'] : 0,

                    //FOLLOW UP
                    "all_followup": response.meta ? response.meta['all_followup'] : 0,
                    "followed_up": response.meta ? response.meta['followed_up'] : 0,
                    "not_followed_up": response.meta ? response.meta['not_followed_up'] : 0,
                    "pending_follow_up": response.meta ? response.meta['pending_follow_up'] : 0,

                    loading_users: false,
                    loading: false
                });
                this.functions.sideScroll();
            } catch (error) {
                this.setState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    loading_users: false,
                    loading: false
                })
            }
        },
        updateClient: async (client, { type }) => {
            client.loading = true;
            await this.promisedSetState({
                clients: this.state.clients
            });
            try {
                if (client.rating && type === 'rating') {
                    await this.calls.updateClient({ id: client.id, rating: client.rating });
                }
                if (client.followUp && type === 'followUp') {
                    await this.calls.updateClient({ id: client.id, followUp: client.followUp });
                }
            } catch (error) { }
            client.loading = false;
            await this.promisedSetState({
                clients: this.state.clients
            });
        },
        getLeads: async (client) => {
            if (!Array.isArray(client.list_leads)) {
                client.loading = true;
                await this.promisedSetState({
                    clients: this.state.clients
                });
                try {
                    let response = await this.calls.getLeads((client.id ? client.id : client._id));
                    response.data = response.data.map((item) => {
                        if (!item.listing.id && item.archived.id) {
                            item.listing = JSON.parse(JSON.stringify(item.archived));
                            item.listing.archived = true;
                            delete item.archived;
                        }
                        return item;
                    });
                    client.list_leads = response.data;
                    await this.promisedSetState({
                        clients: this.state.clients
                    });
                } catch (error) {
                    console.log(error);
                }
                client.loading = false;
                await this.promisedSetState({
                    clients: this.state.clients
                });
            }
        },
        barChartPriceOverTime: (client) => {
            let graph_data = {
                labels: [],
                datasets: [
                    {
                        label: 'Price',
                        data: [],
                        borderColor: 'rgb(69, 63, 241)',
                        backgroundColor: 'rgba(69, 63, 241, 0.7)'
                    }
                ]
            };
            if (Array.isArray(client.list_leads)) {
                client.list_leads.sort(function (a, b) {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                }).map((lead) => {
                    if (lead.listing) {
                        if (this.state.user.enterprise && this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("project") !== -1) {
                            graph_data.labels.push([lead.listing.projectName, moment(lead.createdAt).format("YYYY-MM-DD hh:mm a")]);
                        } else {
                            graph_data.labels.push([lead.listing.name, moment(lead.createdAt).format("YYYY-MM-DD hh:mm a")]);
                        }
                        graph_data.datasets[0].data.push(lead.listing.price);
                    }
                });
            }
            return graph_data;
        },
        barChartTypes: (client) => {
            let graph_data = {
                labels: [],
                datasets: [
                    {
                        label: 'Leads',
                        data: [],
                        borderColor: 'rgb(69, 63, 241)',
                        backgroundColor: 'rgba(69, 63, 241, 0.7)'
                    }
                ]
            };
            let types = {};
            if (Array.isArray(client.list_leads)) {
                client.list_leads.map((lead) => {
                    if (lead.listing) {

                        if (this.state.user.enterprise && this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("project") !== -1) {
                            if (!(lead.listing.projectName in types)) {
                                types[lead.listing.projectName] = 0;
                            }
                            types[lead.listing.projectName] += 1;
                        } else {
                            if (!(lead.listing.name in types)) {
                                types[lead.listing.name] = 0;
                            }
                            types[lead.listing.name] += 1;
                        }


                    }
                });
            }
            for (let key in types) {
                graph_data.labels.push([key, types[key]]);
                graph_data.datasets[0].data.push(types[key]);
            }
            return graph_data;
        },
        barChartRegion: (client) => {
            let graph_data = {
                labels: [],
                datasets: [
                    {
                        label: 'Leads',
                        data: [],
                        borderColor: 'rgb(69, 63, 241)',
                        backgroundColor: 'rgba(69, 63, 241, 0.7)'
                    }
                ]
            };
            let types = {};
            if (Array.isArray(client.list_leads)) {
                client.list_leads.map((lead) => {
                    if (lead.listing && lead.listing.area && lead.listing.area !== "") {
                        if (!(lead.listing.area in types)) {
                            types[lead.listing.area] = 0;
                        }
                        types[lead.listing.area] += 1;
                    } else {
                        if (!('unknown' in types)) {
                            types['unknown'] = 0;
                        }
                        types['unknown'] += 1;
                    }
                });
            }
            for (let key in types) {
                graph_data.labels.push([key, types[key]]);
                graph_data.datasets[0].data.push(types[key]);
            }
            return graph_data;
        },
        exportToCsv: () => {

            let headers = {
                //LEGACY
                created: "Created",
                name: "Name",
                phone: 'Phone',
                email: "Email",
                rating: "Rating",
                follow_up: "Follow-up",
                status: "Status",
                latest_lead: "Latest lead",
                leads: "Total leads"
            };

            if (this.renders.basicUI()) {
                //BASIC
                delete headers.rating;
                delete headers.follow_up;
                delete headers.status;
                headers.latest_lead = "Latest inquiry";
                headers.leads = "Total inquiries";
            } else if (!this.renders.basicUI() && !this.renders.legacyUI()) {
                //SCALE
                headers.rating = "Latest rating";
                headers.follow_up = "Latest follow-up";
                delete headers.leads;
            }

            let itemsFormatted = [];

            this.state.clients.forEach((item) => {
                let new_item = {
                    created: item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD hh:mm a") : '-',
                    name: item.name ? item.name : "-",
                    phone: item.phone ? item.phone : "-",
                    email: item.email ? item.email : "-",
                    ...(!this.renders.basicUI() && { rating: item.rating && item.rating.name ? item.rating.name : '-' }),
                    ...(!this.renders.basicUI() && { follow_up: item.followUp && item.followUp.name ? item.followUp.name : '-' }),
                    ...(!this.renders.basicUI() && { status: '-' }),
                    latest_lead: item.latestLead ? moment(item.latestLead).format("YYYY-MM-DD hh:mm a") : '-',
                    ...((this.renders.basicUI() || this.renders.legacyUI()) && { leads: item.leads ? item.leads : '-' }),
                };
                if (!this.renders.basicUI() && this.renders.legacyUI()) {
                    if (item.double_qualified_actions > 0) {
                        new_item.status = 'Double-qualified';
                    }
                    if (item.qualified_actions > 0 && item.double_qualified_actions < 1) {
                        new_item.status = 'Qualified';
                    }
                    if (item.pre_qualified_actions > 0 && item.qualified_actions < 1) {
                        new_item.status = 'Pre-qualified';
                    }
                    if (item.unqualified_actions > 0 && item.pre_qualified_actions < 1 && item.qualified_actions < 1) {
                        new_item.status = 'Unqualified';
                    }
                } else if (!this.renders.basicUI() && !this.renders.legacyUI()) {
                    if (item.unqualified_leads > 0) {
                        new_item.status = 'Unqualified';
                    }
                    if (item.pre_qualified_leads > 0) {
                        new_item.status = 'Pre-qualified';
                    }
                    if (item.qualified_leads > 0) {
                        new_item.status = 'Qualified';
                    }
                    if (item.post_qualified_leads > 0) {
                        new_item.status = 'Post-qualified';
                    }
                }
                itemsFormatted.push(new_item);
            });

            let fileTitle = 'clients';

            if (this.renders.basicUI()) {
                fileTitle = 'contacts'
            }

            exportCSVFile(headers, itemsFormatted, fileTitle);

            function exportCSVFile(headers, items, fileTitle) {
                if (headers) {
                    items.unshift(headers);
                }
                // Convert Object to JSON
                let jsonObject = JSON.stringify(items);
                let csv = convertToCSV(jsonObject);
                let exportedFilenmae = fileTitle + '.csv' || 'export.csv';
                let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    let link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        let url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            }

            function convertToCSV(objArray) {
                let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                let str = '';
                for (let i = 0; i < array.length; i++) {
                    let line = '';
                    for (let index in array[i]) {
                        if (line != '') line += ',';
                        line += array[i][index];
                    }
                    str += line + '\r\n';
                }
                return str;
            }
        },
        removeClient: async () => {
            let selected_feed = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({
                remove: null,
                clients: this.state.clients.map((item) => {
                    if (item.id == selected_feed.id) {
                        item.loading_remove = true;
                    }
                    return item;
                })
            });
            try {
                let response = await this.calls.removeClient(selected_feed.id);
                await this.promisedSetState({
                    clients: this.state.clients.filter((item) => { return response.data.id != item.id }),
                    remove: null,
                    remove_name: "",
                })
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error.body ? error.body.message : "Something went wrong",
                    remove: null,
                    remove_name: "",
                    clients: this.state.clients.map((item) => {
                        if (item.id == selected_feed.id) {
                            item.loading_remove = false;
                        }
                        return item;
                    })
                })
            }
        },
        sideScroll: () => {
            try {
                const slider = document.querySelector('#table_container');
                let isDown = false;
                let startX;
                let scrollLeft;
                slider.addEventListener('mousedown', (e) => {
                    isDown = true;
                    slider.classList.add('active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;
                });
                slider.addEventListener('mouseleave', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mouseup', () => {
                    isDown = false;
                    slider.classList.remove('active');
                });
                slider.addEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 1;
                    slider.scrollLeft = scrollLeft - walk;
                });
            } catch (error) { }
        }
    };

    calls = {
        getClients: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', data);
            let user = userRegister.get();
            let url = "";
            if (user && user.enterprise && user.enterprise.legacy && user.enterprise.legacy.value == "enabled") {
                if (this.props.proptree) {
                    url = apiRegister.url.proptree + "/getProptreeClients?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&type=" + this.state.tab.value + "&ranking=" + this.state.ranking.value + "&followup=" + this.state.follow_up.value;
                } else {
                    url = apiRegister.url.api + "/client/listClientsLight?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&type=" + this.state.tab.value + "&ranking=" + this.state.ranking.value + "&followup=" + this.state.follow_up.value;
                }
            } else {
                url = apiRegister.url.api + "/client/listClients?page=" + this.state.page + "&limit=" + this.state.page_count + "&orderBy=" + this.state.order.value + "&sortBy=" + this.state.sort.value + "&type=" + this.state.tab.value + "&ranking=" + this.state.ranking.value + "&followup=" + this.state.follow_up.value;
            }
            return apiRegister.call(options, url);
        },
        updateClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/client/updateClient?id=" + data.id;
            return apiRegister.call(options, url);
        },
        getLeads: (client) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/lead/listClientLeads?orderBy=descending&sortBy=createdAt" + (client ? ("&client=" + client) : "");
            return apiRegister.call(options, url);
        },
        removeClient: (lead) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/client/removeClient?id=" + lead;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        getListingUrl: (listing) => {
            if (listing && listing.enterprise) {
                if (listing.enterprise.adboosterType && listing.enterprise.adboosterType.value === 'boostabostad') {
                    if (listing.enterprise.version && (listing.enterprise.version.id === 1 || listing.enterprise.version.value === "live")) {
                        return "https://lp.boostabostad.se/" + listing.adboosterId;
                    } else {
                        return "https://lp.devboostabostad.se/" + listing.adboosterId;
                    }
                } else {
                    if (listing.enterprise.version && (listing.enterprise.version.id === 1 || listing.enterprise.version.value === "live")) {
                        return "https://lp.propertybooster.io/" + listing.adboosterId;
                    } else {
                        return "https://lp.devpropertybooster.com/" + listing.adboosterId;
                    }
                }
            } else {
                return "-";
            }
        },
        clientExtraInformation: (leads, qualifion) => {
            let extraInformation = null;
            try {
                leads.map((lead) => {
                    if (Array.isArray(lead.actions)) {
                        lead.actions.map((action) => {
                            if (action.type && action.type.value === qualifion && action.extraInformation !== '') {
                                extraInformation = action.extraInformation;
                            }
                        });
                    }
                });
            } catch (eror) { }
            return extraInformation;
        },
        clientLatestFailedExtraInformation: (leads, qualifion) => {
            let extraInformation = null;
            try {
                leads.map((lead) => {
                    if (Array.isArray(lead.actions)) {
                        lead.actions.map((action) => {
                            if (action.type && action.type.value === qualifion && action.extraInformation !== '' && action.otherInformation.indexOf("did not pass double") !== -1) {
                                extraInformation = action.extraInformation;
                            }
                        });
                    }
                });
            } catch (error) { }
            return extraInformation;
        },
        clientLatestPreQualifiedExtraInformation: (leads, qualifion) => {
            let extraInformation = null;
            try {
                leads.map((lead) => {
                    if (Array.isArray(lead.actions)) {
                        lead.actions.map((action) => {
                            if (action.type && action.type.value === qualifion && action.extraInformation !== '' && (action.extraInformation.indexOf("buyer") !== -1) || action.extraInformation.indexOf("renter") !== -1) {
                                extraInformation = action.extraInformation;
                            }
                        });
                    }
                });
            } catch (error) { }
            return extraInformation;
        },
        conversationMessages: (agent) => {
            let messages = [];
            try {
                if (Array.isArray(agent.conversations)) {
                    agent.conversations.map((item) => {
                        if (Array.isArray(item.messages)) {
                            item.messages.filter((message) => {
                                return message.reply && (message.reply_template == "post_qualify_crossroad" || message.reply_template == "post_qualify_checkpoint" || message.reply_template == "post_qualify_question_agent" || message.reply_template == "post_qualify_question_inquiry" || message.reply_template == "post_qualify_question_budget_below" || message.reply_template == "post_qualify_question_timeframe")
                            }).map((message) => {
                                messages.push(message);
                            });
                        }
                    });
                }
            } catch (error) { }
            return messages;
        },
        failedDoubleQualify: (agent, reason, checkAgent) => {
            let failed = false;
            let result = "";
            try {
                if (Array.isArray(agent.conversations)) {
                    agent.conversations.map((item) => {
                        let listing = null;
                        agent.listings.map((inner_listing) => {
                            if (inner_listing.id == item.listing) {
                                listing = inner_listing;
                            }
                        });
                        if (Array.isArray(item.messages)) {
                            item.messages.filter((message) => {
                                return message.reply
                            }).map((message) => {
                                if (checkAgent) {
                                    if (message.reply_template === "post_qualify_question_agent" && message.text == "Yes") {
                                        failed = true;
                                        result = "agent"
                                    }
                                } else {
                                    if (message.reply_template === "post_qualify_crossroad" && message.text == "No") {
                                        failed = true;
                                    }
                                    if (message.reply_template === "post_qualify_checkpoint" && message.text == "No") {
                                        failed = true;
                                    }
                                    if (message.reply_template === "post_qualify_question_agent" && message.text == "Yes") {
                                        failed = true;
                                    }
                                    if (message.reply_template === "post_qualify_question_inquiry" && message.text == "I wish to list a property") {
                                        failed = true;
                                        result = "wish to list a property"
                                    }
                                    if (message.reply_template === "post_qualify_question_inquiry" && message.text == "Something else (job, etc)") {
                                        failed = true;
                                        result = "looking for something else (job, etc)"
                                    }
                                    if (message.reply_template === "post_qualify_question_budget_below" && message.text == "Yes") {
                                        failed = true;
                                        if (listing && listing.forSale && (+listing.price / 1500000) < 1) {
                                            failed = false;
                                        } else if (listing && listing.forRent && (+listing.price / 75000) < 1) {
                                            failed = false;
                                        } else {
                                            failed = true;
                                        }
                                        if (failed) {
                                            result = "low budget"
                                        }
                                    }
                                }

                            });
                        }
                    });
                }
            } catch (error) { }
            return reason ? result : failed;
        },
        legacyUI: () => {
            return !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value == 'enabled');
        },
        basicUI: () => {
            return (window.location.href.indexOf("proptr") !== -1) || (this.state.user && this.state.user.enterprise && this.state.user.enterprise.basicSwitch && this.state.user.enterprise.basicSwitch.value == 'enabled');
        },
        creativeUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.creativeSwitch && this.state.user.enterprise.creativeSwitch.value == 'enabled';
        },
        proptreeUI: () => {
            return this.state.user && this.state.user.enterprise && this.state.user.enterprise.proptreeSwitch && this.state.user.enterprise.proptreeSwitch.value == 'enabled';
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };

    render() {
        return (
            <div className="grid grid-cols-12 sm:p-0 p-6 pt-0 h-full min-h-full bg-gray-50">

                {/*LOADING*/}
                {
                    this.state.loading &&
                    <div className="col-span-12 relative min-h-screen flex justify-center items-start">
                        <div className="w-full h-full flex justify-center items-center flex-col pb-32">
                            <div style={{ borderTopColor: "transparent" }}
                                class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            <div className="font-semibold text-sm mt-4">Loading ...</div>
                        </div>
                    </div>
                }

                {/*REMOVE MODAL*/}
                <WarningModal
                    open={this.state.remove ? true : false}
                    title={"Delete Client"}
                    description={"Are you sure you want to delete this client? This action cannot be undone"}
                    cancelButtonText={"cancel"}
                    submitButtonText={"remove"}
                    disableSubmitButton={this.state.remove && this.state.remove_name != this.state.remove.phone}
                    showInput={true}
                    copyInput={this.state.remove ? this.state.remove.phone : ""}
                    inputTitle={'Type "Phone" to remove'}
                    inputError={(this.state.remove && this.state.remove_name && this.state.remove_name !== "" && this.state.remove_name != this.state.remove.phone) ? "Names not matching" : false}
                    onClose={(value) => {
                        this.setState({ remove: null })
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.phone) {
                            this.functions.removeClient();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({ remove_name: value })
                    }}
                />

                {/*CREATE USER*/}
                {
                    !this.props.proptree &&
                    <CreateLeadNew
                        client={this.state.client_lead}
                        open={this.state.open_lead}
                        toggle={() => {
                            this.setState({
                                client_lead: {},
                                open_lead: !this.state.open_lead
                            })
                        }}
                        onUser={async (user) => {

                        }}
                    />
                }

                {/*CREATE USER*/}
                {
                    !this.props.proptree &&
                    <CreateClient
                        user={this.state.selected}
                        open={this.state.open}
                        toggle={() => {
                            this.setState({
                                open: !this.state.open
                            })
                        }}
                        onUser={async (user) => {
                            await this.promisedSetState({
                                page: 1,
                                page_count: 10,
                                total: 0,
                                sort: { id: 1, name: "Created", value: 'createdAt' },
                            });
                            this.functions.getClients();
                        }}
                        onUpdate={async (user) => {
                            this.state.clients = this.state.clients.map((item) => {
                                if (item.id == user.id) {
                                    item = user;
                                }
                                return item;
                            });
                            await this.promisedSetState({
                                clients: this.state.clients
                            })
                        }}
                    />
                }

                {/*OPTION*/}
                {
                    !this.renders.basicUI() &&
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 pr-2 mb-4 md:mb-6">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.tab}
                                options={[
                                    { id: 1, name: "All qualifications", value: 'total', count: this.state.total },
                                    { id: 2, name: "All qualified", value: 'relevant', count: this.state.relevant },
                                    { id: 3, name: "Post-qualified ", value: 'double-qualified', count: this.state['double-qualified'] },
                                    { id: 4, name: "Qualified ", value: 'qualified', count: this.state.qualified },
                                    { id: 5, name: "Pre-qualified ", value: 'pre-qualified', count: this.state['pre-qualified'] },
                                    { id: 6, name: "Unqualified", value: 'unqualified', count: this.state.unqualified }
                                ].filter((item) => {
                                    if (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") {
                                        return item.value !== "relevant";
                                    } else {
                                        return true;
                                    }
                                })}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        tab: option,
                                        page: 1
                                    });
                                    this.functions.getClients();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*RANKED*/}
                {
                    !this.renders.basicUI() &&
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 md:pr-2 md:mb-6 pl-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.ranking}
                                options={[
                                    { id: 1, name: "All ratings", value: 'all', count: this.state.all_ratings },
                                    { id: 7, name: "Signed deal", value: 'signed_deal', count: this.state.signed_deal },
                                    { id: 2, name: "High intent", value: 'high', count: this.state.high },
                                    { id: 3, name: "Medium intent", value: 'medium', count: this.state.medium },
                                    { id: 4, name: "Low intent", value: 'low', count: this.state.low },
                                    { id: 5, name: "Spam", value: 'blocked', count: this.state.block },
                                    { id: 6, name: "Unrated", value: 'unrated', count: this.state.unrated }
                                ].map((item) => {
                                    if (!(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled")) {
                                        delete item.count;
                                    }
                                    return item;
                                })}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        ranking: option,
                                        page: 1
                                    });
                                    this.functions.getClients();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*FOLLOW UP*/}
                {
                    !this.renders.basicUI() &&
                    !this.state.loading &&
                    <div className="col-span-6 md:col-span-2 mb-4 pr-2 md:pr-0 md:mb-6 md:pl-2">
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.follow_up}
                                options={[
                                    { id: 1, name: "All follow-ups", value: 'all', count: this.state.all_followup },
                                    { id: 2, name: "Followed up", value: 'followed_up', count: this.state.followed_up },
                                    { id: 3, name: "Not followed up", value: 'not_followed_up', count: this.state.not_followed_up },
                                    { id: 4, name: "Pending follow-up", value: 'pending_follow_up', count: this.state.pending_follow_up }
                                ].map((item) => {
                                    if (!(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled")) {
                                        delete item.count;
                                    }
                                    return item;
                                })}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        follow_up: option,
                                        page: 1
                                    });
                                    this.functions.getClients();
                                }}
                            />
                        </div>
                    </div>
                }

                {
                    !this.renders.basicUI() &&
                    <div className="col-span-0 hidden md:block md:col-span-2"></div>
                }
                {
                    this.renders.basicUI() &&
                    <div className="col-span-0 hidden md:block md:col-span-8"></div>
                }

                {/*SORT*/}
                {
                    !this.state.loading &&
                    <div className={this.renders.basicUI() ? "col-span-12 md:col-span-2 mb-4 md:mb-6 pl-0 md:pr-2" : "col-span-6 md:col-span-2 mb-4 md:mb-6 pl-2 md:pl-0 md:pr-2"}>
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.sort}
                                options={[
                                    { id: 6, name: (this.renders.basicUI() ? "Latest inquiry" : "Latest lead"), value: 'latestLead' },
                                    { id: 4, name: "Total leads", value: 'totalLeads' },
                                    { id: 1, name: "Created", value: 'createdAt' },
                                    { id: 2, name: "Name", value: 'name' },
                                    { id: 3, name: "Email", value: 'email' }
                                ].filter((item) => {
                                    if (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") {
                                        return item.value !== "totalLeads";
                                    } else {
                                        return true;
                                    }
                                })}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        sort: option,
                                        page: 1
                                    });
                                    this.functions.getClients();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*ORDER*/}
                {
                    !this.state.loading &&
                    <div className={this.state.user.manager ? "col-span-12 md:col-span-2 mb-4 md:mb-6 md:pl-2" : "col-span-12 md:col-span-2 mb-4 md:mb-6 md:pl-2"}>
                        <div className="bg-white border rounded-md">
                            <Dropdown
                                selected={this.state.order}
                                options={[
                                    { id: 1, name: "Descending", value: 'descending' },
                                    { id: 2, name: "Ascending", value: 'ascending' }
                                ]}
                                searchInput={false}
                                onChange={async (option) => {
                                    await this.promisedSetState({
                                        order: option,
                                        page: 1
                                    });
                                    this.functions.getClients();
                                }}
                            />
                        </div>
                    </div>
                }

                {/*AGENTS*/}
                {
                    !this.state.loading &&
                    <div className="col-span-12">
                        <div className="">
                            {
                                <section className="bg-white shadow-lg border rounded-lg">
                                    <div className="flex p-4 items-center lg:px-6 border-b border-gray-100">
                                        <div className="font-sm font-semibold flex-1 flex">
                                            {this.renders.basicUI() ? "All contacts" : this.state.tab.name}
                                        </div>
                                        {
                                            this.state.user && (this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") && (this.state.user.enterprise && this.state.user.enterprise.managerUIremoveClient && this.state.user.enterprise.managerUIremoveClient.value === "enabled") &&
                                            this.state.user.manager && !this.renders.basicUI() &&
                                            <div onClick={() => {
                                                this.setState({
                                                    open: true,
                                                    selected: null
                                                })
                                            }} className={(this.renders.basicUI() ? "text-white bg-purple-500 shadow hover:bg-purple-600 " : "text-purple-500 bg-purple-100 hover:text-purple-700 ") + " text-sm px-4 mr-4 h-10 rounded-md bg-purple-100 border-purple-500 font-medium cursor-pointer items-center flex flex-row "}>
                                                <PlusCircleIcon className="w-5 h-5 mr-2" />
                                                Create
                                            </div>
                                        }
                                        {
                                            this.state.user.manager &&
                                            <div onClick={() => {
                                                this.functions.exportToCsv();
                                            }} className={(this.renders.basicUI() ? "text-white bg-purple-500 shadow hover:bg-purple-600 " : "text-purple-500 bg-purple-100 hover:text-purple-700 ") + " text-sm px-4 h-10 rounded-md bg-purple-100 border-purple-500 font-medium cursor-pointer items-center flex flex-row "}>
                                                <DownloadIcon className="w-5 h-5 mr-2" />
                                                Export
                                            </div>
                                        }
                                    </div>
                                    {
                                        this.state.clients.length < 1 &&
                                        <div className="h-64 flex items-center justify-center border-b border-gray-100 relative">
                                            {
                                                !this.state.loading_users &&
                                                <div className="font-medium text-sm">No <span className="text-purple-500">{this.renders.basicUI() ? "Contacts" : "Clients"}</span> available</div>
                                            }
                                            {
                                                this.state.loading_users &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }

                                    {
                                        this.state.clients.length > 0 &&
                                        <div className="md:hidden lg:hidden relative min-h-110">
                                            {
                                                this.state.clients.map((agent, index) => {
                                                    return (
                                                        <div className="p-4 border-b">
                                                            <div className="flex flex-row items-center">
                                                                <span className="inline-block h-10 w-10 relative rounded-full overflow-hidden bg-gray-100" style={{ minWidth: "2.5rem" }}>
                                                                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                    </svg>
                                                                    {
                                                                        agent.image &&
                                                                        <div className="bg-cover z-5 top-0 left-0 right-0 bottom-0 absolute bg-center inline-block h-10 w-10 rounded-full overflow-hidden" style={{ backgroundImage: 'url(' + agent.image + ')' }}>

                                                                        </div>
                                                                    }
                                                                </span>
                                                                {
                                                                    !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                    <div className="ml-4 whitespace-no-wrap">
                                                                        <div style={window.innerWidth < 640 ? { maxWidth: "115px" } : {}} className="text-sm text-gray-900 whitespace-no-wrap truncate">{agent.name && agent.name !== "" && agent.name !== " " ? agent.name : agent.company && agent.company !== "" && agent.company !== " " ? agent.company : '...'}</div>
                                                                        <div className="text-sm text-gray-500 cursor-pointer whitespace-no-wrap">
                                                                            Actions {agent.actions ? agent.actions : "0"}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className="flex flex-1"></div>
                                                                {
                                                                    !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                    agent.double_qualified_actions > 0 &&
                                                                    <div className="bg-green-100 text-green-500 px-3 font-medium text-xs h-7 flex justify-center items-center rounded-full">{'Double-qualified'}</div>
                                                                }
                                                                {
                                                                    !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                    agent.qualified_actions > 0 && agent.double_qualified_actions < 1 &&
                                                                    <div className="bg-purple-100 text-purple-500 px-3 font-medium text-xs h-7 flex justify-center items-center rounded-full">{"Qualified"}</div>
                                                                }
                                                                {
                                                                    !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                    agent.pre_qualified_actions > 0 && agent.qualified_actions < 1 &&
                                                                    <div className="bg-purple-100 text-purple-500 px-3 font-medium text-xs h-7 flex justify-center items-center rounded-full">{"Pre-qualified"}</div>
                                                                }
                                                                {
                                                                    !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                    agent.unqualified_actions > 0 && agent.pre_qualified_actions < 1 && agent.qualified_actions < 1 && agent.double_qualified_actions < 1 &&
                                                                    <div className="bg-purple-100 text-purple-500 px-3 font-medium text-xs h-7 flex justify-center items-center rounded-full">{"Unqualified"}</div>
                                                                }
                                                            </div>
                                                            {
                                                                (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        Name
                                                                    </div>
                                                                    <div className="text-sm text-gray-900">
                                                                        <div className="relative inline-flex">
                                                                            {agent.name ? agent.name : "..."}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="mt-4">
                                                                <div className="text-xs font-medium mb-1">
                                                                    Phone
                                                                </div>
                                                                <div className="text-sm text-gray-900">
                                                                    <div className="relative inline-flex">
                                                                        {agent.phone ? agent.phone : "..."}
                                                                        {
                                                                            (agent.has_whatsapp || agent.whatsapp) &&
                                                                            <img style={{ right: "-17px", top: "4px" }} className="absolute w-3" src={require("../../assets/images/whatsapp_icon.svg")} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <div className="text-xs font-medium mb-1">
                                                                    Email
                                                                </div>
                                                                <div className="text-sm text-gray-900">
                                                                    {agent.email ? agent.email : "..."}
                                                                </div>
                                                            </div>
                                                            {
                                                                !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                this.renders.failedDoubleQualify(agent) && !(agent.double_qualified_actions > 0) &&
                                                                <div className="border-red-500 border mt-2 sm:col-span-3 text-xs text-red-500 font-medium p-4">
                                                                    failed double qualification
                                                                </div>
                                                            }
                                                            {
                                                                //legacy mobile (manager and agent can rate client)
                                                                //TODO: only update ratingDate when updateClient is called (currently both ratingDate and followUpDate dates are updated)
                                                                !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        <div className="flex flex-row items-center">
                                                                            Rate client
                                                                        </div>
                                                                    </div>
                                                                    <SingleSelection
                                                                        select={async (item) => {
                                                                            agent.rating = item;
                                                                            await this.promisedSetState({
                                                                                clients: this.state.clients
                                                                            });
                                                                            this.functions.updateClient(agent, { type: 'rating' });
                                                                        }}
                                                                        selected={agent.rating ? agent.rating : { id: 0, name: 'Select rating', value: null }}
                                                                        options={[{ id: 5, name: 'Signed deal', value: 'signed_deal' }, { id: 1, name: 'High intent', value: 'high' }, { id: 2, name: 'Medium intent', value: 'medium' }, { id: 3, name: 'Low intent', value: 'low' }, { id: 4, name: 'Spam', value: 'block', red: true }]}
                                                                    />
                                                                    {
                                                                        agent.ratingAgent && agent.ratingAgent.id &&
                                                                        <div className="text-purple-500 mt-2 pl-1 rounded-md font-medium text-xs">
                                                                            By: {agent.ratingAgent.name}
                                                                            {
                                                                                agent.ratingDate &&
                                                                                <div className="text-xxxs" >{agent.ratingDate ? moment(agent.ratingDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                !this.renders.basicUI() &&
                                                                //light mobile (locked)
                                                                //TODO: only update ratingDate when updateClient is called (currently both ratingDate and followUpDate dates are updated)
                                                                this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled" &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        <div className="flex flex-row items-center">
                                                                            Latest rating
                                                                        </div>
                                                                    </div>
                                                                    <SingleSelection
                                                                        disabled={true}
                                                                        select={async (item) => {
                                                                            agent.rating = item;
                                                                            await this.promisedSetState({
                                                                                clients: this.state.clients
                                                                            });
                                                                            this.functions.updateClient(agent, { type: 'rating' });
                                                                        }}
                                                                        selected={agent.rating ? agent.rating : { id: 0, name: 'Select rating', value: null }}
                                                                        options={[{ id: 5, name: 'Signed deal', value: 'signed_deal' }, { id: 1, name: 'High intent', value: 'high' }, { id: 2, name: 'Medium intent', value: 'medium' }, { id: 3, name: 'Low intent', value: 'low' }, { id: 4, name: 'Spam', value: 'block', red: true }]}
                                                                    />
                                                                    {
                                                                        agent.ratingAgent && agent.ratingAgent.id &&
                                                                        <div className="text-purple-500 mt-2 pl-1 rounded-md font-medium text-xs">
                                                                            By: {agent.ratingAgent.name}
                                                                            {
                                                                                agent.ratingDate &&
                                                                                <div className="text-xxxs" >{agent.ratingDate ? moment(agent.ratingDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                //legacy mobile (anyone can follow-up client)
                                                                //TODO: only update followUpDate when updateClient is called (currently both ratingDate and followUpDate dates are updated)
                                                                !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        <div className="flex flex-row items-center">
                                                                            Follow-up client
                                                                        </div>
                                                                    </div>
                                                                    <SingleSelection
                                                                        select={async (item) => {
                                                                            agent.followUp = item;
                                                                            await this.promisedSetState({
                                                                                clients: this.state.clients
                                                                            });
                                                                            this.functions.updateClient(agent, { type: 'followUp' });
                                                                        }}
                                                                        selected={agent.followUp ? agent.followUp : { id: 0, name: 'Select follow-up', value: null }}
                                                                        options={[
                                                                            { id: 5, name: 'Followed up', value: 'followed_up' },
                                                                            { id: 1, name: ' Not followed up', value: 'not_followed_up' },
                                                                            { id: 2, name: 'Pending follow-up', value: 'pending_follow_up' }
                                                                        ]}
                                                                    />
                                                                    {
                                                                        agent.followUpAgent && agent.followUpAgent.id &&
                                                                        <div className="text-purple-500 mt-2 pl-1 rounded-md font-medium text-xs">
                                                                            By: {agent.followUpAgent.name}
                                                                            {
                                                                                agent.followUpDate &&
                                                                                <div className="text-xxxs" >{agent.followUpDate ? moment(agent.followUpDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                !this.renders.basicUI() &&
                                                                //light mobile (locked)
                                                                //TODO: only update followUpDate when updateClient is called (currently both ratingDate and followUpDate dates are updated)
                                                                this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled" &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        <div className="flex flex-row items-center">
                                                                            Latest follow-up
                                                                        </div>
                                                                    </div>
                                                                    <SingleSelection
                                                                        disabled={true}
                                                                        select={async (item) => {
                                                                            agent.followUp = item;
                                                                            await this.promisedSetState({
                                                                                clients: this.state.clients
                                                                            });
                                                                            this.functions.updateClient(agent, { type: 'followUp' });
                                                                        }}
                                                                        selected={agent.followUp ? agent.followUp : { id: 0, name: 'Select follow-up', value: null }}
                                                                        options={[
                                                                            { id: 5, name: 'Followed up', value: 'followed_up' },
                                                                            { id: 1, name: ' Not followed up', value: 'not_followed_up' },
                                                                            { id: 2, name: 'Pending follow-up', value: 'pending_follow_up' }
                                                                        ]}
                                                                    />
                                                                    {
                                                                        agent.followUpAgent && agent.followUpAgent.id &&
                                                                        <div className="text-purple-500 mt-2 pl-1 rounded-md font-medium text-xs">
                                                                            By: {agent.followUpAgent.name}
                                                                            {
                                                                                agent.followUpDate &&
                                                                                <div className="text-xxxs" >{agent.followUpDate ? moment(agent.followUpDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                //failed post-qualification with answers
                                                                !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                Array.isArray(agent.list_leads) &&
                                                                this.renders.clientExtraInformation(agent.list_leads, 'pre-qualified') &&
                                                                this.renders.failedDoubleQualify(agent) &&
                                                                !(agent.double_qualified_actions > 0) &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        Post-qualification results <span className="text-xxxs" >(latest available)</span>
                                                                    </div>
                                                                    <div className="text-sm text-red-500">
                                                                        {
                                                                            //if only answers exist from pre-qualification client rejected to answer
                                                                            !this.renders.clientLatestFailedExtraInformation(agent.list_leads, 'pre-qualified') &&
                                                                            <span>
                                                                                I prefer not to answer questions over WhatsApp, contact me about my requirements.
                                                                            </span>
                                                                        }
                                                                        {
                                                                            //if answers exist from post-qualification display answers (note this action got pre-qualification status why need to filter out any pre-qualification answers)
                                                                            this.renders.clientLatestFailedExtraInformation(agent.list_leads, 'pre-qualified') &&
                                                                            <span>
                                                                                {
                                                                                    this.renders.clientLatestFailedExtraInformation(agent.list_leads, 'pre-qualified')
                                                                                }
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                //failed post-qualification without answers
                                                                !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                Array.isArray(agent.list_leads) &&
                                                                !this.renders.clientExtraInformation(agent.list_leads, 'pre-qualified') &&
                                                                this.renders.failedDoubleQualify(agent) &&
                                                                !(agent.double_qualified_actions > 0) &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        Post-qualification results <span className="text-xxxs" >(latest available)</span>
                                                                    </div>
                                                                    <div className="text-sm text-red-500">
                                                                        <span>I prefer not to answer questions over WhatsApp, contact me about my requirements.</span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                //Passed pre-qualification via questionnaire
                                                                !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                Array.isArray(agent.list_leads) &&
                                                                this.renders.clientLatestPreQualifiedExtraInformation(agent.list_leads, 'pre-qualified') &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        Pre-qualification results <span className="text-xxxs" >(latest available)</span>
                                                                    </div>
                                                                    <div className="text-sm text-purple-500">
                                                                        <span>
                                                                            {
                                                                                this.renders.clientExtraInformation(agent.list_leads, 'pre-qualified')
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                //Passed pre-qualification via chatbot
                                                                //TODO: add request retrieved from chatbot
                                                            }
                                                            {
                                                                //passed post-qualification
                                                                !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                Array.isArray(agent.list_leads) &&
                                                                this.renders.clientExtraInformation(agent.list_leads, 'double-qualified') &&
                                                                !this.renders.failedDoubleQualify(agent) &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        Post-qualification results <span className="text-xxxs" >(latest available)</span>
                                                                    </div>
                                                                    <div className="text-sm text-green-500">
                                                                        <span>
                                                                            {
                                                                                this.renders.clientExtraInformation(agent.list_leads, 'double-qualified')
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                //passed post-qualification but failed after
                                                                !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                Array.isArray(agent.list_leads) &&
                                                                this.renders.clientExtraInformation(agent.list_leads, 'double-qualified') &&
                                                                this.renders.failedDoubleQualify(agent) &&
                                                                agent.double_qualified_actions > 0 &&
                                                                <div className="mt-4">
                                                                    <div className="text-xs font-medium mb-1">
                                                                        Post-qualification results <span className="text-xxxs" >(latest available)</span>
                                                                    </div>
                                                                    <div className="text-sm text-green-500">
                                                                        <span>
                                                                            {
                                                                                this.renders.clientExtraInformation(agent.list_leads, 'double-qualified')
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {
                                                                /*
                                                                Array.isArray(agent.list_leads) &&
                                                                this.renders.clientExtraInformation(agent.list_leads, 'pre-qualified') &&
                                                                <div className="mt-4">
                                                                    {
                                                                        Array.isArray(agent.list_leads) &&
                                                                        this.renders.clientExtraInformation(agent.list_leads, 'pre-qualified') &&
                                                                    <div className="text-xs font-medium mb-1">
                                                                        Qualification info
                                                                    </div>
                                                                    }
                                                                    <div className="text-sm text-purple-500">
                                                                        {
                                                                            Array.isArray(agent.list_leads) &&
                                                                            this.renders.clientExtraInformation(agent.list_leads, 'pre-qualified') &&
                                                                            <span>
                                                                                {
                                                                                    this.renders.clientExtraInformation(agent.list_leads, 'pre-qualified')
                                                                                }
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        Array.isArray(agent.list_leads) &&
                                                                        this.renders.clientExtraInformation(agent.list_leads, 'double-qualified') &&
                                                                        <div className="text-sm text-orange-500 mt-4">
                                                                            {
                                                                                this.renders.clientExtraInformation(agent.list_leads, 'double-qualified')
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                                */
                                                            }
                                                            <div key={agent.email} className={(agent.open ? "h-64" : "h-0 overflow-hidden") + " transition-height duration-500 ease-in-out border-gray-100 relative"}>
                                                                {
                                                                    agent.loading &&
                                                                    <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                            class="w-5 h-5 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                                    </div>
                                                                }
                                                                <div className="relative h-full w-full pt-6">
                                                                    <Bar
                                                                        options={{
                                                                            maintainAspectRatio: false,
                                                                            interaction: {
                                                                                intersect: false,
                                                                            },
                                                                            legend: {
                                                                                display: false
                                                                            },
                                                                            scales: {
                                                                                x: {
                                                                                    stacked: false,
                                                                                },
                                                                                y: {
                                                                                    stacked: false,
                                                                                },
                                                                                xAxes: [{
                                                                                    ticks: {
                                                                                        fontSize: 8
                                                                                    }
                                                                                }],
                                                                                yAxes: [{
                                                                                    ticks: {
                                                                                        fontSize: 8,
                                                                                        beginAtZero: true,
                                                                                        callback: function (value, index, values) {
                                                                                            return nFormatter(parseInt(value));
                                                                                            function nFormatter(num) {
                                                                                                if (num >= 1000000000) {
                                                                                                    num = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
                                                                                                }
                                                                                                if (num >= 1000000) {
                                                                                                    num = (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                                                                                                }
                                                                                                if (num >= 1000) {
                                                                                                    num = (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                                                                                                }
                                                                                                return "AED" + " " + num;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }],
                                                                            },
                                                                        }}
                                                                        data={this.functions.barChartPriceOverTime(agent)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {
                                                                <div className={(agent.open ? "h-64" : "h-0") + " transition-height duration-500 ease-in-out flex flex-1 flex-col pt-6 overflow-hidden"}>
                                                                    {
                                                                        //LEGACY
                                                                        !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                        <div className="flex flex-1 flex-col overflow-x-scroll overflow-y-auto pt-4 pb-4">
                                                                            <div className="p-4 flex pt-0 pb-0 flex-row w-full pl-4 sm:pl-6" style={{ minHeight: "3rem", width: "80rem" }}>
                                                                                <div className="flex flex-col flex-1 justify-end">
                                                                                    <div className="font-medium text-sm mb-3">
                                                                                        Client journey
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex flex-col flex-1 justify-end items-end">
                                                                                    <div className="font-medium text-sm text-purple-500 mb-3">
                                                                                        Unqualified
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex flex-col flex-1 justify-end items-end">
                                                                                    <div className="font-medium text-sm text-purple-500 mb-3">
                                                                                        Pre-qualified
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex flex-col flex-1 justify-end items-end">
                                                                                    <div className="font-medium text-sm text-purple-500 mb-3">
                                                                                        Qualified
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex flex-col flex-1 justify-end items-end">
                                                                                    <div className="font-medium text-sm text-purple-500 mb-3">
                                                                                        Post-qualified
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                Array.isArray(agent.list_leads) &&
                                                                                agent.list_leads.map((item) => {
                                                                                    if (item.type === "unqualified") {
                                                                                        item.index = 0;
                                                                                    }
                                                                                    if (item.type === "pre-qualified") {
                                                                                        item.index = 1;
                                                                                    }
                                                                                    if (item.type === "qualified") {
                                                                                        item.index = 2;
                                                                                    }
                                                                                    if (item.type === "double-qualified") {
                                                                                        item.index = 3;
                                                                                    }
                                                                                    return item;
                                                                                }).sort((a, b) => { return b.index - a.index }).map((lead, index) => {
                                                                                    return (
                                                                                        <div className="p-4 flex pt-0 pb-0 flex-row w-full pl-4 sm:pl-6 mb-2" style={{ minHeight: "3rem", width: "80rem" }}>
                                                                                            {
                                                                                                ['listing', 'unqualified', 'pre-qualified', 'qualified', 'double-qualified'].filter((item) => {
                                                                                                    if (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") {
                                                                                                        return item !== "pre-qualified";
                                                                                                    } else {
                                                                                                        return true;
                                                                                                    }
                                                                                                }).map((qualifion) => {
                                                                                                    return (
                                                                                                        <div className="flex flex-col flex-1 justify-center">
                                                                                                            {
                                                                                                                qualifion === "listing" &&
                                                                                                                <div className="flex flex-row">
                                                                                                                    {
                                                                                                                        lead.listing.image &&
                                                                                                                        <div className="flex flex-col">
                                                                                                                            <div className="flex-shrink-0 h-10 w-10 relative">
                                                                                                                                <img className="h-10 w-10 rounded-full" src={lead.listing.image} alt="" />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                    <div className={"ml-4 mr-4 whitespace-no-wrap"}>
                                                                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{lead.listing.name}</div>
                                                                                                                        {
                                                                                                                            <div className="text-sm text-gray-500 cursor-pointer whitespace-no-wrap hover:text-purple-500 hover:underline">
                                                                                                                                <a href={this.renders.getListingUrl(lead.listing)} target="_blank" >#{lead.listing.reference}</a>
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {
                                                                                                                qualifion !== "listing" &&
                                                                                                                <div className="w-full pt-1">
                                                                                                                    <div className={(qualifion === "double-qualified" ? " rounded-r-md" : "") + (qualifion === "unqualified" ? " rounded-l-md" : "") + (Array.isArray(lead.actions) && lead.actions.filter((item) => {
                                                                                                                        if (qualifion === "unqualified") {
                                                                                                                            return true
                                                                                                                        } else if (qualifion === "pre-qualified") {
                                                                                                                            return (item.type && item.type.value === "pre-qualified") || (item.type && item.type.value === "qualified") || (item.type && item.type.value === "double-qualified")
                                                                                                                        } else if (qualifion === "qualified") {
                                                                                                                            return (item.type && item.type.value === "qualified") || (item.type && item.type.value === "double-qualified")
                                                                                                                        } else if (qualifion === "double-qualified") {
                                                                                                                            return (item.type && item.type.value === "double-qualified")
                                                                                                                        } else {
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                    }).length > 0 ? " bg-purple-500" : " bg-gray-100") + " h-2 w-full"}></div>
                                                                                                                    <div className="text-sm text-gray-500 whitespace-no-wrap flex-1 flex items-end justify-end mt-2">
                                                                                                                        {
                                                                                                                            Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).length > 0 &&
                                                                                                                            <div className="flex flex-row">
                                                                                                                                <div className="mr-3">
                                                                                                                                    {moment(lead.actions.filter((item) => { return item.type && item.type.value === qualifion })[0].createdAt).format("YYYY-MM-DD hh:mm a")}
                                                                                                                                </div>
                                                                                                                                {
                                                                                                                                    <div className="flex flex-row">
                                                                                                                                        {
                                                                                                                                            ['bing', 'facebook', 'instagram', 'google', 'internal', '', 'tiktok', 'snapchat', 'proptree'].filter((channel) => {
                                                                                                                                                return Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel == channel }).length > 0
                                                                                                                                            }).map((channel) => {
                                                                                                                                                return (
                                                                                                                                                    <div style={{ marginTop: "1px" }} className="flex items-center p-1 justify-center rounded-full bg-purple-500 border border-white h-5 w-5 -ml-1">
                                                                                                                                                        {
                                                                                                                                                            Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'bing' }).length > 0 &&
                                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_bing.png")} />
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'facebook' && !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") }).length > 0 &&
                                                                                                                                                            <img style={{ width: '5px' }} src={require("../../assets/images/AB_Social_facebook.png")} />
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'facebook' && (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") }).length > 0 &&
                                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_meta.png")} />
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'instagram' }).length > 0 &&
                                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_instagram.png")} />
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'google' }).length > 0 &&
                                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_google.png")} />
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'internal' }).length > 0 &&
                                                                                                                                                            <img style={{ maxWidth: '105%' }} src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === '' }).length > 0 &&
                                                                                                                                                            <img style={{ maxWidth: '105%' }} src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'tiktok' }).length > 0 &&
                                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_tiktok.png")} />
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'snapchat' }).length > 0 &&
                                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_snapchat.png")} />
                                                                                                                                                        }
                                                                                                                                                        {
                                                                                                                                                            Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'proptree' }).length > 0 &&
                                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_proptree.png")} />
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            })
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                            ||
                                                                                                                            <span className="opacity-0">Skipped</span>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {
                                                                        //LIGHT
                                                                        this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled" &&
                                                                        <div className="flex flex-1 flex-col overflow-x-scroll overflow-y-auto pt-4 pb-4">
                                                                            <div className="p-4 flex pt-0 pb-0 flex-row pl-4 sm:pl-6" style={{ minHeight: "3rem", width: "80rem" }}>
                                                                                <div className="flex flex-col flex-1 justify-end">
                                                                                    <div className="font-medium text-sm mb-3">
                                                                                        Client journey
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex flex-col flex-1 justify-end items-end">
                                                                                    <div className="font-medium text-sm text-purple-500 mb-3">
                                                                                        Unqualified
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex flex-col flex-1 justify-end items-end">
                                                                                    <div className="font-medium text-sm text-purple-500 mb-3">
                                                                                        Pre-qualified
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex flex-col flex-1 justify-end items-end">
                                                                                    <div className="font-medium text-sm text-purple-500 mb-3">
                                                                                        Qualified
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex flex-col flex-1 justify-end items-end">
                                                                                    <div className="font-medium text-sm text-purple-500 mb-3">
                                                                                        Post-qualified
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                Array.isArray(agent.list_leads) &&
                                                                                agent.list_leads.map((item) => {
                                                                                    if (item.type === "unqualified") {
                                                                                        item.index = 0;
                                                                                    }
                                                                                    if (item.type === "pre-qualified") {
                                                                                        item.index = 1;
                                                                                    }
                                                                                    if (item.type === "qualified") {
                                                                                        item.index = 2;
                                                                                    }
                                                                                    if (item.type === "double-qualified") {
                                                                                        item.index = 3;
                                                                                    }
                                                                                    return item;
                                                                                }).sort((a, b) => { return b.index - a.index }).map((lead, index) => {
                                                                                    return (
                                                                                        <div className="p-4 flex pt-0 pb-0 flex-row w-full pl-4 sm:pl-6 mb-2" style={{ minHeight: "3rem", width: "80rem" }}>
                                                                                            {
                                                                                                ['listing', 'unqualified', 'pre-qualified', 'qualified', 'double-qualified'].map((qualifion) => {
                                                                                                    return (
                                                                                                        <div className="flex flex-col flex-1 justify-center">
                                                                                                            {
                                                                                                                qualifion === "listing" &&
                                                                                                                <div className="flex flex-row">
                                                                                                                    {
                                                                                                                        lead.listing.image &&
                                                                                                                        <div className="flex flex-col">
                                                                                                                            <div className="flex-shrink-0 h-10 w-10 relative">
                                                                                                                                <img className="h-10 w-10 rounded-full" src={lead.listing.image} alt="" />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                    <div className={"ml-4 whitespace-no-wrap"}>
                                                                                                                        <div className="text-sm text-gray-900 whitespace-no-wrap">{lead.listing ? (this.state.user.enterprise && this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("project") !== -1 ? lead.listing.projectName : lead.listing.name) : "-"}</div>
                                                                                                                        {
                                                                                                                            <div className="text-sm text-gray-500 whitespace-no-wrap">
                                                                                                                                <span>#{lead.listing.reference}</span>
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {
                                                                                                                qualifion !== "listing" &&
                                                                                                                <div className="w-full pt-1">
                                                                                                                    <div className={(qualifion === "double-qualified" ? " rounded-r-md" : "") + (qualifion === "unqualified" ? " rounded-l-md" : "") + ([{ type: lead.type }].filter((item) => {
                                                                                                                        if (qualifion === "unqualified") {
                                                                                                                            return true
                                                                                                                        } else if (qualifion === "pre-qualified") {
                                                                                                                            return (lead.type === "pre-qualified") || (lead.type === "qualified") || (lead.type === "double-qualified")
                                                                                                                        } else if (qualifion === "qualified") {
                                                                                                                            return (lead.type === "qualified") || (lead.type === "double-qualified")
                                                                                                                        } else if (qualifion === "double-qualified") {
                                                                                                                            return (lead.type === "double-qualified")
                                                                                                                        } else {
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                    }).length > 0 ? " bg-purple-500" : " bg-gray-100") + " h-2 w-full"}></div>
                                                                                                                    <div className="text-sm text-gray-500 whitespace-no-wrap flex-1 flex items-end justify-end mt-2">
                                                                                                                        {
                                                                                                                            qualifion === "unqualified" &&
                                                                                                                            <div className="flex flex-row justify-end">
                                                                                                                                <div className="mr-3">
                                                                                                                                    {lead.gotUnqualified && moment(lead.gotUnqualified).format("YYYY-MM-DD hh:mm a")}
                                                                                                                                </div>
                                                                                                                                {
                                                                                                                                    lead.gotUnqualified &&
                                                                                                                                    <div style={{ marginTop: "1px" }} className="flex items-center p-1 justify-center rounded-full bg-purple-500 border border-white h-5 w-5 -ml-1">
                                                                                                                                        {
                                                                                                                                            (lead.channel === 'facebook' || lead.channel === 'meta') &&
                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_meta.png")} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            lead.channel === 'google' &&
                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_google.png")} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            lead.channel === 'tiktok' &&
                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_tiktok.png")} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            lead.channel === 'snapchat' &&
                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_snapchat.png")} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            lead.channel === 'proptree' &&
                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_proptree.png")} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            lead.channel === 'internal' &&
                                                                                                                                            <img style={{ maxWidth: '105%' }} src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            lead.channel === '' &&
                                                                                                                                            <img style={{ maxWidth: '105%' }} src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            //false &&
                                                                                                                            lead.type !== "unqualified" && qualifion === "unqualified" &&
                                                                                                                            <div style={{ minHeight: "21px" }}></div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            false &&
                                                                                                                            lead.type !== "unqualified" && qualifion === "unqualified" &&
                                                                                                                            <div className="flex flex-row justify-end">
                                                                                                                                <div
                                                                                                                                    style={{ minHeight: "21px" }}
                                                                                                                                    className="w-full"></div>
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            qualifion === "pre-qualified" &&
                                                                                                                            <div className="flex flex-row justify-end">
                                                                                                                                <div className="mr-3">
                                                                                                                                    {lead.gotPreQualified && moment(lead.gotPreQualified).format("YYYY-MM-DD hh:mm a")}
                                                                                                                                </div>
                                                                                                                                {
                                                                                                                                    lead.gotPreQualified &&
                                                                                                                                    <div style={{ marginTop: "1px" }} className="flex items-center p-1 justify-center rounded-full bg-purple-500 border border-white h-5 w-5 -ml-1">
                                                                                                                                        {
                                                                                                                                            (lead.channel === 'facebook' || lead.channel === 'meta') &&
                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_meta.png")} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            lead.channel === 'google' &&
                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_google.png")} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            lead.channel === 'tiktok' &&
                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_tiktok.png")} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            lead.channel === 'snapchat' &&
                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_snapchat.png")} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            lead.channel === 'proptree' &&
                                                                                                                                            <img className="w-full" src={require("../../assets/images/AB_Social_proptree.png")} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            lead.channel === 'internal' &&
                                                                                                                                            <img style={{ maxWidth: '105%' }} src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            lead.channel === '' &&
                                                                                                                                            <img style={{ maxWidth: '105%' }} src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            //false &&
                                                                                                                            lead.type !== "pre-qualified" && qualifion === "pre-qualified" &&
                                                                                                                            <div style={{ minHeight: "21px" }}></div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            false &&
                                                                                                                            lead.type !== "pre-qualified" && qualifion === "pre-qualified" &&
                                                                                                                            <div className="flex flex-row justify-end">
                                                                                                                                <div
                                                                                                                                    style={{ minHeight: "21px" }}
                                                                                                                                    className="w-full"></div>
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            lead.type === "qualified" && qualifion === "qualified" &&
                                                                                                                            <div className="flex flex-row">
                                                                                                                                <div className="mr-3">
                                                                                                                                    {lead.gotQualified && moment(lead.gotQualified).format("YYYY-MM-DD hh:mm a")}
                                                                                                                                </div>
                                                                                                                                {
                                                                                                                                    lead.gotQualified &&
                                                                                                                                    <div style={{ marginTop: "1px" }} className="flex items-center p-1 justify-center rounded-full bg-purple-500 border border-white h-5 w-5 -ml-1">
                                                                                                                                        <img style={{ maxWidth: '105%' }} src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                    </div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            //false &&
                                                                                                                            lead.type !== "qualified" && qualifion === "qualified" &&
                                                                                                                            <div style={{ minHeight: "21px" }}></div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            false &&
                                                                                                                            lead.type !== "qualified" && qualifion === "qualified" &&
                                                                                                                            <div className="flex flex-row justify-end">
                                                                                                                                <div
                                                                                                                                    style={{ minHeight: "21px" }}
                                                                                                                                    className="w-full"></div>
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            lead.type === "double-qualified" && qualifion === "double-qualified" &&
                                                                                                                            <div className="flex flex-row">
                                                                                                                                <div className="mr-3">
                                                                                                                                    {lead.gotPostQualified && moment(lead.gotPostQualified).format("YYYY-MM-DD hh:mm a")}
                                                                                                                                </div>
                                                                                                                                {
                                                                                                                                    lead.gotPostQualified &&
                                                                                                                                    <div style={{ marginTop: "1px" }} className="flex items-center p-1 justify-center rounded-full bg-purple-500 border border-white h-5 w-5 -ml-1">
                                                                                                                                        <img style={{ maxWidth: '105%' }} src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                    </div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            //false &&
                                                                                                                            lead.type !== "double-qualified" && qualifion === "double-qualified" &&
                                                                                                                            <div style={{ minHeight: "21px" }}></div>
                                                                                                                        }
                                                                                                                        {
                                                                                                                            false &&
                                                                                                                            lead.type !== "double-qualified" && qualifion === "double-qualified" &&
                                                                                                                            <div className="flex flex-row justify-end">
                                                                                                                                <div
                                                                                                                                    style={{ minHeight: "21px" }}
                                                                                                                                    className="w-full"></div>
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                !this.renders.basicUI() &&
                                                                <div className="flex justify-center items-start mt-4">
                                                                    <div
                                                                        onClick={async () => {
                                                                            agent.open = !agent.open;
                                                                            if (agent.open) {
                                                                                this.functions.getLeads(agent);
                                                                            }
                                                                            this.setState({
                                                                                users: this.state.users
                                                                            });
                                                                        }}
                                                                        className={"cursor-pointer mr-2 relative inline-flex items-center px-4 py-2 border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                                    >
                                                                        {!agent.open && <span className="">Show more</span>}{agent.open && <span className="">Hide</span>}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                            {
                                                this.state.loading_users &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }

                                    {
                                        this.state.clients.length > 0 &&
                                        <div id={"table_container"} className="relative overflow-x-scroll hidden md:block min-h-110">
                                            <table className="min-w-full">
                                                <thead className="">
                                                    <tr>

                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {
                                                        this.state.clients.map((agent, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <tr key={agent.id} className="border-b border-gray-100">
                                                                        {
                                                                            !this.props.proptree &&
                                                                            <td className="text-left pl-6">
                                                                                <div className="flex flex-row items-center justify-start relative">
                                                                                    {
                                                                                        <Menu as="div" className="relative inline-block text-left">
                                                                                            <div>
                                                                                                <Menu.Button className="inline-flex relative items-center hover:bg-gray-50 justify-center rounded-md bg-white border w-10 h-10 text-sm font-medium text-gray-900">
                                                                                                    {
                                                                                                        !agent.loading_remove &&
                                                                                                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                                    }
                                                                                                    {
                                                                                                        agent.loading_remove &&
                                                                                                        <div className="top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-red-50 bg-opacity-50">
                                                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                                                class="w-4 h-4 border-2 border-red-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                                        </div>
                                                                                                    }
                                                                                                </Menu.Button>
                                                                                            </div>
                                                                                            <Transition
                                                                                                as={Fragment}
                                                                                                enter="transition ease-out duration-100"
                                                                                                enterFrom="transform opacity-0 scale-95"
                                                                                                enterTo="transform opacity-100 scale-100"
                                                                                                leave="transition ease-in duration-75"
                                                                                                leaveFrom="transform opacity-100 scale-100"
                                                                                                leaveTo="transform opacity-0 scale-95"
                                                                                            >
                                                                                                <Menu.Items className="absolute border left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg">
                                                                                                    <div className="py-1">
                                                                                                        {
                                                                                                            [
                                                                                                                //{ name: "Edit", value: "edit", icon: PencilAltIcon },
                                                                                                                { name: this.renders.basicUI() ? "Show inquiries" : "Show leads", value: "show_leads", icon: ExternalLinkIcon },
                                                                                                                { name: "Create lead", value: "create_lead", icon: StarIcon },
                                                                                                                { name: "Remove", value: "remove", icon: TrashIcon }
                                                                                                            ].filter((item) => {
                                                                                                                if (item.value == "remove") {
                                                                                                                    return (this.state.user && this.state.user.enterprise && this.state.user.enterprise.managerUIremoveClient && this.state.user.enterprise.managerUIremoveClient.value === "enabled")
                                                                                                                } else if (item.value == "create_lead" /*|| item.value == "edit"*/) {
                                                                                                                    return !this.renders.basicUI() && this.state.user && (this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") && (this.state.user.enterprise && this.state.user.enterprise.managerUIremoveClient && this.state.user.enterprise.managerUIremoveClient.value === "enabled")
                                                                                                                } else {
                                                                                                                    return true;
                                                                                                                }
                                                                                                            }).map((item) => {
                                                                                                                return (
                                                                                                                    <Menu.Item>
                                                                                                                        {({ active }) => (
                                                                                                                            <div
                                                                                                                                onClick={() => {
                                                                                                                                    if (item.value == "show_leads") {
                                                                                                                                        if (this.state.user.access && this.state.user.access.id === 1) {
                                                                                                                                            this.props.history.push("/manager/leads?client=" + agent.id);
                                                                                                                                        } else {
                                                                                                                                            this.props.history.push("/agent/leads?client=" + agent.id);
                                                                                                                                        }
                                                                                                                                    } else if (item.value == "create_lead") {
                                                                                                                                        this.setState({
                                                                                                                                            open_lead: true,
                                                                                                                                            client_lead: agent
                                                                                                                                        });
                                                                                                                                    } else if (item.value == "remove") {
                                                                                                                                        this.setState({
                                                                                                                                            remove: agent
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                }}
                                                                                                                                className={classNames(
                                                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                                                    'flex items-center px-4 py-2 font-medium text-sm cursor-pointer',
                                                                                                                                    (item.value == "disable" || item.value == "remove") ? "text-red-500 bg-red-100" : ""
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                <div className="flex flex-1">{item.name}</div>
                                                                                                                                <item.icon className="w-5" />
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </Menu.Item>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                </Menu.Items>
                                                                                            </Transition>
                                                                                        </Menu>
                                                                                    }
                                                                                    {
                                                                                        !this.renders.basicUI() &&
                                                                                        <div
                                                                                            onClick={async () => {
                                                                                                agent.open = !agent.open;
                                                                                                if (agent.open) {
                                                                                                    this.functions.getLeads(agent);
                                                                                                }
                                                                                                this.setState({
                                                                                                    clients: this.state.clients
                                                                                                });
                                                                                            }} className="inline-flex relative whitespace-no-wrap cursor-pointer hover:bg-gray-50 items-center ml-2 justify-center rounded-md bg-white border px-4 h-10 text-sm font-medium text-gray-900">
                                                                                            {
                                                                                                !agent.open &&
                                                                                                <div>More info</div>
                                                                                            }
                                                                                            {
                                                                                                agent.open &&
                                                                                                <div>Close</div>
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            false &&
                                                                            !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                            this.renders.failedDoubleQualify(agent) && !agent.postQualifiedStatus &&
                                                                            <div style={{ marginTop: '12px' }}></div>
                                                                        }
                                                                        {
                                                                            false &&
                                                                            <td className="flex items-center py-4">
                                                                                <span className="inline-block h-10 w-10 relative rounded-full overflow-hidden bg-gray-100" style={{ minWidth: "2.5rem" }}>
                                                                                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                                    </svg>
                                                                                    {
                                                                                        agent.image !== '' &&
                                                                                        <div className="bg-cover z-5 top-0 left-0 right-0 bottom-0 absolute bg-center inline-block h-10 w-10 rounded-full overflow-hidden" style={{ backgroundImage: 'url(' + agent.image + ')' }}>

                                                                                        </div>
                                                                                    }
                                                                                </span>
                                                                            </td>
                                                                        }
                                                                        <td className="flex items-center px-6 py-4">
                                                                            <span className="inline-block mr-2 h-10 w-10 relative rounded-full overflow-hidden bg-gray-100">
                                                                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                                </svg>
                                                                                {
                                                                                    agent.image !== '' &&
                                                                                    <div className="bg-cover z-5 top-0 left-0 right-0 bottom-0 absolute bg-center inline-block h-10 w-10 rounded-full overflow-hidden" style={{ backgroundImage: 'url(' + agent.image + ')' }}>

                                                                                    </div>
                                                                                }
                                                                            </span>
                                                                            <div className="flex flex-col items-left">
                                                                                {
                                                                                    !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                                    this.renders.failedDoubleQualify(agent, true, true) && !(agent.double_qualified_actions > 0) &&
                                                                                    <div style={{ marginTop: '12px' }}></div>
                                                                                }
                                                                                {
                                                                                    <div className="text-sm truncate whitespace-no-wrap text-gray-900">{agent.name && agent.name !== "" && agent.name !== " " ? agent.name : agent.company && agent.company !== "" && agent.company !== " " ? agent.company : '-'}</div>
                                                                                }
                                                                                <div className="text-sm text-gray-500">Name</div>
                                                                                {
                                                                                    !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                                    this.renders.failedDoubleQualify(agent, true, true) && !(agent.double_qualified_actions > 0) &&
                                                                                    <div className="text-xxs font-semibold text-red-500 whitespace-no-wrap">{this.renders.failedDoubleQualify(agent, true, true)}</div>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4">
                                                                            <div className="flex flex-col items-center">
                                                                                <div className="text-sm truncate whitespace-no-wrap text-gray-900">{agent.phone ? agent.phone : '-'}</div>
                                                                                <div className="text-sm text-gray-500 relative flex flex-row">Phone
                                                                                    {
                                                                                        (agent.has_whatsapp || agent.whatsapp) &&
                                                                                        <img style={{ right: "-17px", top: "4px" }} className="absolute w-3" src={require("../../assets/images/whatsapp_icon.svg")} />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="text-center px-6 py-4">
                                                                            <div className="flex flex-col items-center">
                                                                                <div className="text-sm truncate whitespace-no-wrap text-gray-900">{agent.email ? agent.email : '-'}</div>
                                                                                <div className="text-sm text-gray-500">Email</div>
                                                                            </div>
                                                                        </td>
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                            <td className="text-center px-6 py-4">
                                                                                <div className="text-sm whitespace-no-wrap text-gray-900">{agent.rating ? agent.rating.name : "-"}</div>
                                                                                <div className="text-sm text-gray-500">Rating</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled" &&
                                                                            <td className="text-center px-6 py-4">
                                                                                <div className="text-sm whitespace-no-wrap text-gray-900">{agent.rating ? agent.rating.name : "-"}</div>
                                                                                <div className="text-sm whitespace-no-wrap text-gray-500">Latest rating</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                            <td className="text-center px-6 py-4">
                                                                                {
                                                                                    this.renders.failedDoubleQualify(agent) && this.renders.failedDoubleQualify(agent, true) !== "" && !(agent.double_qualified_actions > 0) &&
                                                                                    <div style={{ marginTop: '12px' }}></div>
                                                                                }
                                                                                <div className="text-sm whitespace-no-wrap text-gray-900">{agent.followUp ? agent.followUp.name : "-"}</div>
                                                                                <div className="text-sm whitespace-no-wrap text-gray-500">Follow-up</div>
                                                                                {
                                                                                    this.renders.failedDoubleQualify(agent) && this.renders.failedDoubleQualify(agent, true) !== "" && !(agent.double_qualified_actions > 0) &&
                                                                                    <div className="text-xxs font-semibold text-red-500 whitespace-no-wrap">{this.renders.failedDoubleQualify(agent, true)}</div>
                                                                                }
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled" &&
                                                                            <td className="text-center px-6 py-4">
                                                                                <div className="text-sm whitespace-no-wrap text-gray-900">{agent.followUp ? agent.followUp.name : "-"}</div>
                                                                                <div className="text-sm whitespace-no-wrap text-gray-500">Latest follow-up</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                            <td className="text-center px-6 py-4">
                                                                                {
                                                                                    this.renders.failedDoubleQualify(agent) && !(agent.double_qualified_actions > 0) &&
                                                                                    <div style={{ marginTop: '12px' }}></div>
                                                                                }
                                                                                {
                                                                                    agent.double_qualified_actions > 0 &&
                                                                                    <div className="text-sm text-green-500 whitespace-no-wrap">{'Double-qualified'}</div>
                                                                                }
                                                                                {
                                                                                    agent.qualified_actions > 0 && agent.double_qualified_actions < 1 &&
                                                                                    <div className="text-sm text-purple-500 whitespace-no-wrap">{"Qualified"}</div>
                                                                                }
                                                                                {
                                                                                    agent.pre_qualified_actions > 0 && agent.qualified_actions < 1 &&
                                                                                    <div className="text-sm text-purple-500 whitespace-no-wrap">{"Pre-qualified"}</div>
                                                                                }
                                                                                {
                                                                                    agent.unqualified_actions > 0 && agent.pre_qualified_actions < 1 && agent.qualified_actions < 1 && agent.double_qualified_actions < 1 &&
                                                                                    <div className="text-sm text-purple-500 whitespace-no-wrap">{"Unqualified"}</div>
                                                                                }
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Status</div>
                                                                                {
                                                                                    this.renders.failedDoubleQualify(agent) && !(agent.double_qualified_actions > 0) &&
                                                                                    <div className="text-xxs font-semibold text-red-500 whitespace-no-wrap">failed double qualification</div>
                                                                                }
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                            <td className="text-center px-6 py-4">
                                                                                <div className="text-sm whitespace-no-wrap text-gray-900">{agent.unqualified_leads ? agent.unqualified_leads : "-"}</div>
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Unqualified</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                            <td className="text-center px-6 py-4">
                                                                                <div className="text-sm whitespace-no-wrap text-gray-900">{agent.pre_qualified_leads ? agent.pre_qualified_leads : "-"}</div>
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Pre-qualified</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                            <td className="text-center px-6 py-4">
                                                                                <div className="text-sm whitespace-no-wrap text-gray-900">{agent.qualified_leads ? agent.qualified_leads : "-"}</div>
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Qualified</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                            <td className="text-center px-6 py-4">
                                                                                <div className="text-sm whitespace-no-wrap text-gray-900">{agent.post_qualified_leads ? agent.post_qualified_leads : "-"}</div>
                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap">Post-qualified</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                            <td className="text-center whitespace-no-wrap px-6 py-4">
                                                                                <div className="text-sm whitespace-no-wrap text-gray-900">{agent.latestLead ? moment(agent.latestLead).format("YYYY-MM-DD hh:mm a") : "-"}</div>
                                                                                {
                                                                                    !this.renders.basicUI() &&
                                                                                    <div className="text-sm whitespace-no-wrap text-gray-500">Latest lead</div>
                                                                                }
                                                                                {
                                                                                    this.renders.basicUI() &&
                                                                                    <div className="text-sm whitespace-no-wrap text-gray-500">Latest inquiry</div>
                                                                                }
                                                                            </td>
                                                                        }
                                                                        {
                                                                            !this.renders.basicUI() &&
                                                                            !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                            <td className="text-center whitespace-no-wrap px-6 py-4">
                                                                                <div className="text-sm whitespace-no-wrap text-gray-900">{agent.latestLead ? moment(agent.latestLead).format("YYYY-MM-DD hh:mm a") : "-"}</div>
                                                                                <div className="text-sm whitespace-no-wrap text-gray-500">Latest lead</div>
                                                                            </td>
                                                                        }
                                                                        {
                                                                            (this.renders.basicUI() || !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled")) &&
                                                                            <td className="text-center px-6 py-4 whitespace-no-wrap">
                                                                                <div className="text-sm whitespace-no-wrap text-gray-900">{agent.leads ? agent.leads : "-"}</div>
                                                                                {
                                                                                    !this.renders.basicUI() &&
                                                                                    <div className="text-sm whitespace-no-wrap text-gray-500">Total leads</div>
                                                                                }
                                                                                {
                                                                                    this.renders.basicUI() &&
                                                                                    <div className="text-sm whitespace-no-wrap text-gray-500">Total inquiries</div>
                                                                                }
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                    <tr key={agent.email} className={(agent.open ? (this.state.user.manager ? "h-170 border-b" : "h-170 border-b") : "h-0 overflow-hidden") + " transition-height duration-500 ease-in-out border-gray-100 relative"}>
                                                                        {
                                                                            agent.open &&
                                                                            <div className={(this.state.user.manager ? "h-170 " : "h-170 ") + " absolute bg-gray-50 left-0 right-0 flex justify-center items-center"}>
                                                                                <div className="w-full h-full p-6 relative">
                                                                                    <div className="bg-white rounded-md w-full h-full flex flex-col">
                                                                                        <div className="w-full flex flex-col">
                                                                                            <div className="px-4 py-5 sm:px-6">
                                                                                                <h2 className="text-sm font-medium leading-6 text-gray-900">Client Information</h2>
                                                                                            </div>
                                                                                            <div className="w-full grid grid-cols-12">
                                                                                                <div className="col-span-6 px-4 py-5 sm:p-0">
                                                                                                    <dl className="sm:divide-y sm:divide-gray-100">
                                                                                                        {
                                                                                                            !this.renders.basicUI() &&
                                                                                                            //legacy desktop (manager and agent can rate client)
                                                                                                            !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                                                            <div className="h-24 py-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                                <dt className="text-sm font-medium text-gray-500 relative">
                                                                                                                    <div className="flex flex-row items-center">
                                                                                                                        Rate client
                                                                                                                    </div>
                                                                                                                    {
                                                                                                                        agent.ratingAgent && agent.ratingAgent.id &&
                                                                                                                        <div className="text-purple-500 absolute rounded-md font-medium text-xs">
                                                                                                                            By: {agent.ratingAgent.name}
                                                                                                                            {
                                                                                                                                agent.ratingDate &&
                                                                                                                                <div className="text-xxxs" >{agent.ratingDate ? moment(agent.ratingDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                </dt>
                                                                                                                <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-full">
                                                                                                                    <SingleSelection
                                                                                                                        select={async (item) => {
                                                                                                                            agent.rating = item;
                                                                                                                            await this.promisedSetState({
                                                                                                                                clients: this.state.clients
                                                                                                                            });
                                                                                                                            this.functions.updateClient(agent, { type: 'rating' });
                                                                                                                        }}
                                                                                                                        selected={agent.rating ? agent.rating : { id: 0, name: 'Select rating', value: null }}
                                                                                                                        options={[{ id: 5, name: 'Signed deal', value: 'signed_deal' }, { id: 1, name: 'High intent', value: 'high' }, { id: 2, name: 'Medium intent', value: 'medium' }, { id: 3, name: 'Low intent', value: 'low' }, { id: 4, name: 'Spam', value: 'block' }]}
                                                                                                                    />
                                                                                                                </dd>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            !this.renders.basicUI() &&
                                                                                                            //light desktop (locked)
                                                                                                            this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled" &&
                                                                                                            <div className="h-24 py-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                                <dt className="text-sm font-medium text-gray-500 relative">
                                                                                                                    <div className="flex flex-row items-center">
                                                                                                                        Latest rating
                                                                                                                    </div>
                                                                                                                    {
                                                                                                                        agent.ratingAgent && agent.ratingAgent.id &&
                                                                                                                        <div className="text-purple-500 absolute rounded-md font-medium text-xs">
                                                                                                                            By: {agent.ratingAgent.name}
                                                                                                                            {
                                                                                                                                agent.ratingDate &&
                                                                                                                                <div className="text-xxxs" >{agent.ratingDate ? moment(agent.ratingDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                </dt>
                                                                                                                <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-full">
                                                                                                                    <SingleSelection
                                                                                                                        disabled={true}
                                                                                                                        select={async (item) => {
                                                                                                                            agent.rating = item;
                                                                                                                            await this.promisedSetState({
                                                                                                                                clients: this.state.clients
                                                                                                                            });
                                                                                                                            this.functions.updateClient(agent, { type: 'rating' });
                                                                                                                        }}
                                                                                                                        selected={agent.rating ? agent.rating : { id: 0, name: 'Select rating', value: null }}
                                                                                                                        options={[{ id: 5, name: 'Signed deal', value: 'signed_deal' }, { id: 1, name: 'High intent', value: 'high' }, { id: 2, name: 'Medium intent', value: 'medium' }, { id: 3, name: 'Low intent', value: 'low' }, { id: 4, name: 'Spam', value: 'block' }]}
                                                                                                                    />
                                                                                                                </dd>
                                                                                                            </div>
                                                                                                        }
                                                                                                        <div className="h-21 py-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                            <dt className="text-sm font-medium text-gray-500">Name</dt>
                                                                                                            {
                                                                                                                (agent.company && agent.company !== "") &&
                                                                                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{agent.company}</dd>
                                                                                                            }
                                                                                                            {
                                                                                                                !(agent.company && agent.company !== "") &&
                                                                                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{agent.name}</dd>
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div className="h-21 py-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                            <dt className="text-sm font-medium text-gray-500">Email</dt>
                                                                                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{agent.email}</dd>
                                                                                                        </div>
                                                                                                        <div className="py-0 sm:h-21 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                            <dt className="text-sm font-medium text-gray-500">Phone</dt>
                                                                                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                                                                                <div className="relative inline-flex"> {agent.phone}
                                                                                                                    {
                                                                                                                        (agent.has_whatsapp || agent.whatsapp) &&
                                                                                                                        <img style={{ right: "-17px", top: "4px" }} className="absolute w-3" src={require("../../assets/images/whatsapp_icon.svg")} />
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </dd>
                                                                                                        </div>
                                                                                                    </dl>
                                                                                                </div>
                                                                                                <div className="col-span-6 px-4 py-5 sm:p-0">
                                                                                                    <dl className="sm:divide-y sm:divide-gray-100">
                                                                                                        {
                                                                                                            !this.renders.basicUI() &&
                                                                                                            //legacy desktop (anyone can follow-up client)
                                                                                                            !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                                                            <div className="h-24 py-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                                <dt className="text-sm whitespace-no-wrap font-medium text-gray-500 relative">
                                                                                                                    <div className="flex flex-row items-center">
                                                                                                                        Follow-up client
                                                                                                                    </div>
                                                                                                                    {
                                                                                                                        agent.followUpAgent && agent.followUpAgent.id &&
                                                                                                                        <div className="text-purple-500 absolute rounded-md font-medium text-xs">
                                                                                                                            By: {agent.followUpAgent.name}
                                                                                                                            {
                                                                                                                                agent.followUpDate &&
                                                                                                                                <div className="text-xxxs" >{agent.followUpDate ? moment(agent.followUpDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                </dt>
                                                                                                                <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-full">
                                                                                                                    <SingleSelection
                                                                                                                        select={async (item) => {
                                                                                                                            agent.followUp = item;
                                                                                                                            await this.promisedSetState({
                                                                                                                                clients: this.state.clients
                                                                                                                            });
                                                                                                                            this.functions.updateClient(agent, { type: 'followUp' });
                                                                                                                        }}
                                                                                                                        selected={agent.followUp ? agent.followUp : { id: 0, name: 'Select follow-up', value: null }}
                                                                                                                        options={[
                                                                                                                            { id: 5, name: 'Followed up', value: 'followed_up' },
                                                                                                                            { id: 1, name: ' Not followed up', value: 'not_followed_up' },
                                                                                                                            { id: 2, name: 'Pending follow-up', value: 'pending_follow_up' }
                                                                                                                        ]}
                                                                                                                    />
                                                                                                                </dd>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            !this.renders.basicUI() &&
                                                                                                            //light desktop (locked)
                                                                                                            this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled" &&
                                                                                                            <div className="h-24 py-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                                <dt className="text-sm whitespace-no-wrap font-medium text-gray-500 relative">
                                                                                                                    <div className="flex flex-row items-center">
                                                                                                                        Latest follow-up
                                                                                                                    </div>
                                                                                                                    {
                                                                                                                        agent.followUpAgent && agent.followUpAgent.id &&
                                                                                                                        <div className="text-purple-500 absolute rounded-md font-medium text-xs">
                                                                                                                            By: {agent.followUpAgent.name}
                                                                                                                            {
                                                                                                                                agent.followUpDate &&
                                                                                                                                <div className="text-xxxs" >{agent.followUpDate ? moment(agent.followUpDate).format("YYYY-MM-DD hh:mm a") : ""}</div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                </dt>
                                                                                                                <dd className="text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-full">
                                                                                                                    <SingleSelection
                                                                                                                        disabled={true}
                                                                                                                        select={async (item) => {
                                                                                                                            agent.followUp = item;
                                                                                                                            await this.promisedSetState({
                                                                                                                                clients: this.state.clients
                                                                                                                            });
                                                                                                                            this.functions.updateClient(agent, { type: 'followUp' });
                                                                                                                        }}
                                                                                                                        selected={agent.followUp ? agent.followUp : { id: 0, name: 'Select follow-up', value: null }}
                                                                                                                        options={[
                                                                                                                            { id: 5, name: 'Followed up', value: 'followed_up' },
                                                                                                                            { id: 1, name: ' Not followed up', value: 'not_followed_up' },
                                                                                                                            { id: 2, name: 'Pending follow-up', value: 'pending_follow_up' }
                                                                                                                        ]}
                                                                                                                    />
                                                                                                                </dd>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            !this.renders.basicUI() &&
                                                                                                            <div className="py-0 sm:grid sm:gap-4 sm:py-5 sm:px-6 flex flex-col sm:flex-row items-start sm:items-center">
                                                                                                                {
                                                                                                                    //failed post-qualification with answers
                                                                                                                    !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                                                                    Array.isArray(agent.list_leads) &&
                                                                                                                    this.renders.clientExtraInformation(agent.list_leads, 'pre-qualified') &&
                                                                                                                    this.renders.failedDoubleQualify(agent) &&
                                                                                                                    !(agent.double_qualified_actions > 0) &&
                                                                                                                    <div>
                                                                                                                        <div className="text-xs font-medium mb-1">
                                                                                                                            Post-qualification results <span className="text-xxxs" >(latest available)</span>
                                                                                                                        </div>
                                                                                                                        <div className="border text-sm text-red-500 border-red-500 font-medium p-4">
                                                                                                                            {
                                                                                                                                //if only answers exist from pre-qualification client rejected to answer
                                                                                                                                !this.renders.clientLatestFailedExtraInformation(agent.list_leads, 'pre-qualified') &&
                                                                                                                                <span>
                                                                                                                                    I prefer not to answer questions over WhatsApp, contact me about my requirements.
                                                                                                                                </span>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                //if answers exist from post-qualification display answers (note this action got pre-qualification status why need to filter out any pre-qualification answers)
                                                                                                                                this.renders.clientLatestFailedExtraInformation(agent.list_leads, 'pre-qualified') &&
                                                                                                                                <span>
                                                                                                                                    {
                                                                                                                                        this.renders.clientLatestFailedExtraInformation(agent.list_leads, 'pre-qualified')
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {
                                                                                                                    //failed post-qualification without answers
                                                                                                                    !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                                                                    Array.isArray(agent.list_leads) &&
                                                                                                                    !this.renders.clientExtraInformation(agent.list_leads, 'pre-qualified') &&
                                                                                                                    this.renders.failedDoubleQualify(agent) &&
                                                                                                                    !(agent.double_qualified_actions > 0) &&
                                                                                                                    <div>
                                                                                                                        <div className="text-xs font-medium mb-1">
                                                                                                                            Post-qualification results <span className="text-xxxs" >(latest available)</span>
                                                                                                                        </div>
                                                                                                                        <div className="border text-sm text-red-500 border-red-500 font-medium p-4">
                                                                                                                            <span>I prefer not to answer questions over WhatsApp, contact me about my requirements.</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {
                                                                                                                    //Passed pre-qualification via questionnaire
                                                                                                                    !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                                                                    Array.isArray(agent.list_leads) &&
                                                                                                                    this.renders.clientLatestPreQualifiedExtraInformation(agent.list_leads, 'pre-qualified') &&
                                                                                                                    <div>
                                                                                                                        <div className="text-xs font-medium mb-1">
                                                                                                                            Pre-qualification results <span className="text-xxxs" >(latest available)</span>
                                                                                                                        </div>
                                                                                                                        <div className="border text-sm text-purple-500 border-purple-500 font-medium p-4">
                                                                                                                            <span>
                                                                                                                                {
                                                                                                                                    this.renders.clientExtraInformation(agent.list_leads, 'pre-qualified')
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {
                                                                                                                    //Passed pre-qualification via chatbot
                                                                                                                    //TODO: add request retrieved from chatbot
                                                                                                                }
                                                                                                                {
                                                                                                                    //passed post-qualification
                                                                                                                    !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                                                                    Array.isArray(agent.list_leads) &&
                                                                                                                    this.renders.clientExtraInformation(agent.list_leads, 'double-qualified') &&
                                                                                                                    !this.renders.failedDoubleQualify(agent) &&
                                                                                                                    <div>
                                                                                                                        <div className="text-xs font-medium mb-1">
                                                                                                                            Post-qualification results <span className="text-xxxs" >(latest available)</span>
                                                                                                                        </div>
                                                                                                                        <div className="border text-sm text-green-500 border-green-500 font-medium p-4">
                                                                                                                            <span>
                                                                                                                                {
                                                                                                                                    this.renders.clientExtraInformation(agent.list_leads, 'double-qualified')
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {
                                                                                                                    //passed post-qualification but failed after
                                                                                                                    !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                                                                    Array.isArray(agent.list_leads) &&
                                                                                                                    this.renders.clientExtraInformation(agent.list_leads, 'double-qualified') &&
                                                                                                                    this.renders.failedDoubleQualify(agent) &&
                                                                                                                    agent.double_qualified_actions > 0 &&
                                                                                                                    <div>
                                                                                                                        <div className="text-xs font-medium mb-1">
                                                                                                                            Post-qualification results <span className="text-xxxs" >(latest available)</span>
                                                                                                                        </div>
                                                                                                                        <div className="border text-sm text-green-500 border-green-500 font-medium p-4">
                                                                                                                            <span>
                                                                                                                                {
                                                                                                                                    this.renders.clientExtraInformation(agent.list_leads, 'double-qualified')
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        }
                                                                                                    </dl>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        {
                                                                                            !this.renders.basicUI() &&
                                                                                            <div className={(this.state.user.manager ? "h-72" : "h-72") + " grid-cols-3 grid border-t pt-4"}>
                                                                                                <div className="col-span-1 flex flex-col">
                                                                                                    <div className="px-4 pt-0 py-5 sm:px-6">
                                                                                                        <h2 className="text-sm font-medium leading-6 text-gray-900"> Leads over time</h2>
                                                                                                    </div>
                                                                                                    <div className="p-4 pt-0 flex flex-1">
                                                                                                        <Bar
                                                                                                            options={{
                                                                                                                maintainAspectRatio: false,
                                                                                                                interaction: {
                                                                                                                    intersect: false,
                                                                                                                },
                                                                                                                legend: {
                                                                                                                    display: false
                                                                                                                },
                                                                                                                scales: {
                                                                                                                    x: {
                                                                                                                        stacked: false,
                                                                                                                    },
                                                                                                                    y: {
                                                                                                                        stacked: false,
                                                                                                                    },
                                                                                                                    xAxes: [{
                                                                                                                        ticks: {
                                                                                                                            fontSize: 8
                                                                                                                        }
                                                                                                                    }],
                                                                                                                    yAxes: [{
                                                                                                                        ticks: {
                                                                                                                            fontSize: 8,
                                                                                                                            beginAtZero: true,
                                                                                                                            callback: function (value, index, values) {
                                                                                                                                return nFormatter(parseInt(value));
                                                                                                                                function nFormatter(num) {
                                                                                                                                    if (num >= 1000000000) {
                                                                                                                                        num = (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
                                                                                                                                    }
                                                                                                                                    if (num >= 1000000) {
                                                                                                                                        num = (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
                                                                                                                                    }
                                                                                                                                    if (num >= 1000) {
                                                                                                                                        num = (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
                                                                                                                                    }
                                                                                                                                    return "AED" + " " + num;
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }],
                                                                                                                },
                                                                                                            }}
                                                                                                            data={this.functions.barChartPriceOverTime(agent)}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-span-1 flex flex-col">
                                                                                                    <div className="px-4  pt-0  py-5 sm:px-6">
                                                                                                        <h2 className="text-sm font-medium leading-6 text-gray-900"> Property types</h2>

                                                                                                    </div>
                                                                                                    <div className=" p-4  pt-0 flex flex-1">
                                                                                                        <Bar
                                                                                                            options={{
                                                                                                                tooltips: {
                                                                                                                },
                                                                                                                maintainAspectRatio: false,
                                                                                                                interaction: {
                                                                                                                    intersect: false,
                                                                                                                },
                                                                                                                legend: {
                                                                                                                    display: false
                                                                                                                },
                                                                                                                scales: {
                                                                                                                    x: {
                                                                                                                        stacked: false,
                                                                                                                    },
                                                                                                                    y: {
                                                                                                                        stacked: false,
                                                                                                                    },
                                                                                                                    xAxes: [{
                                                                                                                        ticks: {
                                                                                                                            fontSize: 8
                                                                                                                        }
                                                                                                                    }],
                                                                                                                    yAxes: [{
                                                                                                                        ticks: {
                                                                                                                            fontSize: 8,
                                                                                                                            beginAtZero: true,
                                                                                                                            stepSize: 1
                                                                                                                        }
                                                                                                                    }],
                                                                                                                },
                                                                                                            }}
                                                                                                            data={this.functions.barChartTypes(agent)}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-span-1 flex flex-col">
                                                                                                    <div className="px-4 pt-0 py-5 sm:px-6">
                                                                                                        <h2 className="text-sm font-medium leading-6 text-gray-900">Areas</h2>

                                                                                                    </div>
                                                                                                    <div className="flex flex-1 p-4 pt-0">
                                                                                                        <Bar
                                                                                                            options={{
                                                                                                                tooltips: {
                                                                                                                },
                                                                                                                maintainAspectRatio: false,
                                                                                                                interaction: {
                                                                                                                    intersect: false,
                                                                                                                },
                                                                                                                legend: {
                                                                                                                    display: false
                                                                                                                },
                                                                                                                scales: {
                                                                                                                    x: {
                                                                                                                        stacked: false,
                                                                                                                    },
                                                                                                                    y: {
                                                                                                                        stacked: false,
                                                                                                                    },
                                                                                                                    xAxes: [{
                                                                                                                        ticks: {
                                                                                                                            fontSize: 8
                                                                                                                        }
                                                                                                                    }],
                                                                                                                    yAxes: [{
                                                                                                                        ticks: {
                                                                                                                            fontSize: 8,
                                                                                                                            beginAtZero: true,
                                                                                                                            stepSize: 1
                                                                                                                        }
                                                                                                                    }],
                                                                                                                },
                                                                                                            }}
                                                                                                            data={this.functions.barChartRegion(agent)}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            !this.renders.basicUI() &&
                                                                                            <div className="flex flex-1 flex-col border-t pt-6 overflow-hidden">
                                                                                                <div className="p-4 flex pt-0 pb-0 flex-row w-full pl-4 sm:pl-6">
                                                                                                    <div className="flex flex-col flex-1">
                                                                                                        <div className="font-medium text-sm mb-3">
                                                                                                            Client journey
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="flex flex-col flex-1 justify-end items-end">
                                                                                                        <div className="font-medium text-sm text-purple-500 mb-3">
                                                                                                            Unqualified
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="flex flex-col flex-1 justify-end items-end">
                                                                                                        <div className="font-medium text-sm text-purple-500 mb-3">
                                                                                                            Pre-qualified
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="flex flex-col flex-1 justify-end items-end">
                                                                                                        <div className="font-medium text-sm text-purple-500 mb-3">
                                                                                                            Qualified
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="flex flex-col flex-1 justify-end items-end">
                                                                                                        <div className="font-medium text-sm text-purple-500 mb-3">
                                                                                                            Post-qualified
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    //LEGACY
                                                                                                    !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") &&
                                                                                                    <div className="flex flex-1 flex-col overflow-y-scroll pt-4 pb-4">
                                                                                                        {
                                                                                                            Array.isArray(agent.list_leads) &&
                                                                                                            agent.list_leads.map((item) => {
                                                                                                                if (item.type === "unqualified") {
                                                                                                                    item.index = 0;
                                                                                                                }
                                                                                                                if (item.type === "pre-qualified") {
                                                                                                                    item.index = 1;
                                                                                                                }
                                                                                                                if (item.type === "qualified") {
                                                                                                                    item.index = 2;
                                                                                                                }
                                                                                                                if (item.type === "double-qualified") {
                                                                                                                    item.index = 3;
                                                                                                                }
                                                                                                                return item;
                                                                                                            }).sort((a, b) => { return b.index - a.index }).map((lead, index) => {
                                                                                                                return (
                                                                                                                    <div className="p-4 flex pt-0 pb-0 flex-row w-full pl-4 sm:pl-6 mb-2" style={{ minHeight: "3rem" }}>
                                                                                                                        {
                                                                                                                            ['listing', 'unqualified', 'pre-qualified', 'qualified', 'double-qualified'].filter((item) => {
                                                                                                                                if (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") {
                                                                                                                                    return item !== "pre-qualified";
                                                                                                                                } else {
                                                                                                                                    return true;
                                                                                                                                }
                                                                                                                            }).map((qualifion) => {
                                                                                                                                return (
                                                                                                                                    <div className="flex flex-col flex-1 justify-center">
                                                                                                                                        {
                                                                                                                                            qualifion === "listing" &&
                                                                                                                                            <div className="flex flex-row">
                                                                                                                                                {
                                                                                                                                                    lead.listing.image &&
                                                                                                                                                    <div className="flex flex-col">
                                                                                                                                                        <div className="flex-shrink-0 h-10 w-10 relative">
                                                                                                                                                            <img className="h-10 w-10 rounded-full" src={lead.listing.image} alt="" />
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                }
                                                                                                                                                <div className={"ml-4 mr-4 whitespace-no-wrap"}>
                                                                                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{lead.listing.name}</div>
                                                                                                                                                    {
                                                                                                                                                        <div className="text-sm text-gray-500 cursor-pointer whitespace-no-wrap hover:text-purple-500 hover:underline">
                                                                                                                                                            <a href={this.renders.getListingUrl(lead.listing)} target="_blank" >#{lead.listing.reference}</a>
                                                                                                                                                        </div>
                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            qualifion !== "listing" &&
                                                                                                                                            <div className="w-full pt-1">
                                                                                                                                                <div className={(qualifion === "double-qualified" ? " rounded-r-md" : "") + (qualifion === "unqualified" ? " rounded-l-md" : "") + (Array.isArray(lead.actions) && lead.actions.filter((item) => {
                                                                                                                                                    if (qualifion === "unqualified") {
                                                                                                                                                        return true
                                                                                                                                                    } else if (qualifion === "pre-qualified") {
                                                                                                                                                        return (item.type && item.type.value === "pre-qualified") || (item.type && item.type.value === "qualified") || (item.type && item.type.value === "double-qualified")
                                                                                                                                                    } else if (qualifion === "qualified") {
                                                                                                                                                        return (item.type && item.type.value === "qualified") || (item.type && item.type.value === "double-qualified")
                                                                                                                                                    } else if (qualifion === "double-qualified") {
                                                                                                                                                        return (item.type && item.type.value === "double-qualified")
                                                                                                                                                    } else {
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                }).length > 0 ? " bg-purple-500" : " bg-gray-100") + " h-2 w-full"}></div>
                                                                                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap flex-1 flex items-end justify-end mt-2">
                                                                                                                                                    {
                                                                                                                                                        Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).length > 0 &&
                                                                                                                                                        <div className="flex flex-row">
                                                                                                                                                            <div className="mr-3">
                                                                                                                                                                {moment(lead.actions.filter((item) => { return item.type && item.type.value === qualifion })[0].createdAt).format("YYYY-MM-DD hh:mm a")}
                                                                                                                                                            </div>
                                                                                                                                                            {
                                                                                                                                                                <div className="flex flex-row">
                                                                                                                                                                    {
                                                                                                                                                                        ['bing', 'facebook', 'instagram', 'google', 'internal', '', 'tiktok', 'snapchat', 'proptree'].filter((channel) => {
                                                                                                                                                                            return Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel == channel }).length > 0
                                                                                                                                                                        }).map((channel) => {
                                                                                                                                                                            return (
                                                                                                                                                                                <div style={{ marginTop: "1px" }} className="flex items-center p-1 justify-center rounded-full bg-purple-500 border border-white h-5 w-5 -ml-1">
                                                                                                                                                                                    {
                                                                                                                                                                                        Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'bing' }).length > 0 &&
                                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_bing.png")} />
                                                                                                                                                                                    }
                                                                                                                                                                                    {
                                                                                                                                                                                        Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'facebook' && !(this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") }).length > 0 &&
                                                                                                                                                                                        <img style={{ width: '5px' }} src={require("../../assets/images/AB_Social_facebook.png")} />
                                                                                                                                                                                    }
                                                                                                                                                                                    {
                                                                                                                                                                                        Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'facebook' && (this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled") }).length > 0 &&
                                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_meta.png")} />
                                                                                                                                                                                    }
                                                                                                                                                                                    {
                                                                                                                                                                                        Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'instagram' }).length > 0 &&
                                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_instagram.png")} />
                                                                                                                                                                                    }
                                                                                                                                                                                    {
                                                                                                                                                                                        Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'google' }).length > 0 &&
                                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_google.png")} />
                                                                                                                                                                                    }
                                                                                                                                                                                    {
                                                                                                                                                                                        Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'internal' }).length > 0 &&
                                                                                                                                                                                        <img style={{ maxWidth: '105%' }} src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                                                                    }
                                                                                                                                                                                    {
                                                                                                                                                                                        Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === '' }).length > 0 &&
                                                                                                                                                                                        <img style={{ maxWidth: '105%' }} src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                                                                    }
                                                                                                                                                                                    {
                                                                                                                                                                                        Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'tiktok' }).length > 0 &&
                                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_tiktok.png")} />
                                                                                                                                                                                    }
                                                                                                                                                                                    {
                                                                                                                                                                                        Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'snapchat' }).length > 0 &&
                                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_snapchat.png")} />
                                                                                                                                                                                    }
                                                                                                                                                                                    {
                                                                                                                                                                                        Array.isArray(lead.actions) && lead.actions.filter((item) => { return item.type && item.type.value === qualifion }).filter((item) => { return item.channel === channel && channel === 'proptree' }).length > 0 &&
                                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_proptree.png")} />
                                                                                                                                                                                    }
                                                                                                                                                                                </div>
                                                                                                                                                                            )
                                                                                                                                                                        })
                                                                                                                                                                    }
                                                                                                                                                                </div>
                                                                                                                                                            }
                                                                                                                                                        </div>
                                                                                                                                                        ||
                                                                                                                                                        <span className="opacity-0">Skipped</span>
                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    //LIGHT
                                                                                                    this.state.user && this.state.user.enterprise && this.state.user.enterprise.legacy && this.state.user.enterprise.legacy.value === "enabled" &&
                                                                                                    <div className="flex flex-1 flex-col overflow-y-scroll pt-4 pb-4">
                                                                                                        {
                                                                                                            Array.isArray(agent.list_leads) &&
                                                                                                            agent.list_leads.map((item) => {
                                                                                                                if (item.type === "unqualified") {
                                                                                                                    item.index = 0;
                                                                                                                }
                                                                                                                if (item.type === "pre-qualified") {
                                                                                                                    item.index = 1;
                                                                                                                }
                                                                                                                if (item.type === "qualified") {
                                                                                                                    item.index = 2;
                                                                                                                }
                                                                                                                if (item.type === "double-qualified") {
                                                                                                                    item.index = 3;
                                                                                                                }
                                                                                                                return item;
                                                                                                            }).sort((a, b) => { return b.index - a.index }).map((lead, index) => {
                                                                                                                return (
                                                                                                                    <div className="p-4 flex pt-0 pb-0 flex-row w-full pl-4 sm:pl-6 mb-2" style={{ minHeight: "3rem" }}>
                                                                                                                        {
                                                                                                                            ['listing', 'unqualified', 'pre-qualified', 'qualified', 'double-qualified'].map((qualifion) => {
                                                                                                                                return (
                                                                                                                                    <div className="flex flex-col flex-1 justify-center">
                                                                                                                                        {
                                                                                                                                            qualifion === "listing" &&
                                                                                                                                            <div className="flex flex-row">
                                                                                                                                                {
                                                                                                                                                    lead.listing.image &&
                                                                                                                                                    <div className="flex flex-col">
                                                                                                                                                        <div className="flex-shrink-0 h-10 w-10 relative">
                                                                                                                                                            <img className="h-10 w-10 rounded-full" src={lead.listing.image} alt="" />
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                }
                                                                                                                                                <div className={"ml-4 whitespace-no-wrap"}>
                                                                                                                                                    <div className="text-sm text-gray-900 whitespace-no-wrap">{lead.listing ? (this.state.user.enterprise && this.state.user.enterprise.parserType && this.state.user.enterprise.parserType.value.indexOf("project") !== -1 ? lead.listing.projectName : lead.listing.name) : "-"}</div>
                                                                                                                                                    {
                                                                                                                                                        <div className="text-sm text-gray-500 whitespace-no-wrap">
                                                                                                                                                            <span>#{lead.listing.reference}</span>
                                                                                                                                                        </div>
                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            qualifion !== "listing" &&
                                                                                                                                            <div className="w-full pt-1">
                                                                                                                                                <div className={(qualifion === "double-qualified" ? " rounded-r-md" : "") + (qualifion === "unqualified" ? " rounded-l-md" : "") + ([{ type: lead.type }].filter((item) => {
                                                                                                                                                    if (qualifion === "unqualified") {
                                                                                                                                                        return true
                                                                                                                                                    } else if (qualifion === "pre-qualified") {
                                                                                                                                                        return (lead.type === "pre-qualified") || (lead.type === "qualified") || (lead.type === "double-qualified")
                                                                                                                                                    } else if (qualifion === "qualified") {
                                                                                                                                                        return (lead.type === "qualified") || (lead.type === "double-qualified")
                                                                                                                                                    } else if (qualifion === "double-qualified") {
                                                                                                                                                        return (lead.type === "double-qualified")
                                                                                                                                                    } else {
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                }).length > 0 ? " bg-purple-500" : " bg-gray-100") + " h-2 w-full"}></div>
                                                                                                                                                <div className="text-sm text-gray-500 whitespace-no-wrap flex-1 flex items-end justify-end mt-2">
                                                                                                                                                    {
                                                                                                                                                        qualifion === "unqualified" &&
                                                                                                                                                        <div className="flex flex-row justify-end">
                                                                                                                                                            <div className="mr-3">
                                                                                                                                                                {lead.gotUnqualified && moment(lead.gotUnqualified).format("YYYY-MM-DD hh:mm a")}
                                                                                                                                                            </div>
                                                                                                                                                            {
                                                                                                                                                                lead.gotUnqualified &&
                                                                                                                                                                <div style={{ marginTop: "1px" }} className="flex items-center p-1 justify-center rounded-full bg-purple-500 border border-white h-5 w-5 -ml-1">
                                                                                                                                                                    {
                                                                                                                                                                        (lead.channel === 'facebook' || lead.channel === 'meta') &&
                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_meta.png")} />
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        lead.channel === 'google' &&
                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_google.png")} />
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        lead.channel === 'tiktok' &&
                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_tiktok.png")} />
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        lead.channel === 'snapchat' &&
                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_snapchat.png")} />
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        lead.channel === 'proptree' &&
                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_proptree.png")} />
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        lead.channel === 'internal' &&
                                                                                                                                                                        <img style={{ maxWidth: '100%' }} src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        lead.channel === '' &&
                                                                                                                                                                        <img style={{ maxWidth: '100%' }} src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                                                    }
                                                                                                                                                                </div>
                                                                                                                                                            }
                                                                                                                                                        </div>
                                                                                                                                                    }
                                                                                                                                                    {
                                                                                                                                                        //false &&
                                                                                                                                                        lead.type !== "unqualified" && qualifion === "unqualified" &&
                                                                                                                                                        <div style={{ minHeight: "21px" }}></div>
                                                                                                                                                    }
                                                                                                                                                    {
                                                                                                                                                        false &&
                                                                                                                                                        lead.type !== "unqualified" && qualifion === "unqualified" &&
                                                                                                                                                        <div className="flex flex-row justify-end">
                                                                                                                                                            <div
                                                                                                                                                                style={{ minHeight: "21px" }}
                                                                                                                                                                className="w-full"></div>
                                                                                                                                                        </div>
                                                                                                                                                    }
                                                                                                                                                    {
                                                                                                                                                        qualifion === "pre-qualified" &&
                                                                                                                                                        <div className="flex flex-row justify-end">
                                                                                                                                                            <div className="mr-3">
                                                                                                                                                                {lead.gotPreQualified && moment(lead.gotPreQualified).format("YYYY-MM-DD hh:mm a")}
                                                                                                                                                            </div>
                                                                                                                                                            {
                                                                                                                                                                lead.gotPreQualified &&
                                                                                                                                                                <div style={{ marginTop: "1px" }} className="flex items-center p-1 justify-center rounded-full bg-purple-500 border border-white h-5 w-5 -ml-1">
                                                                                                                                                                    {
                                                                                                                                                                        (lead.channel === 'facebook' || lead.channel === 'meta') &&
                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_meta.png")} />
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        lead.channel === 'google' &&
                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_google.png")} />
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        lead.channel === 'tiktok' &&
                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_tiktok.png")} />
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        lead.channel === 'snapchat' &&
                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_snapchat.png")} />
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        lead.channel === 'proptree' &&
                                                                                                                                                                        <img className="w-full" src={require("../../assets/images/AB_Social_proptree.png")} />
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        lead.channel === 'internal' &&
                                                                                                                                                                        <img style={{ maxWidth: '105%' }} src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                                                    }
                                                                                                                                                                    {
                                                                                                                                                                        lead.channel === '' &&
                                                                                                                                                                        <img style={{ maxWidth: '105%' }} src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                                                    }
                                                                                                                                                                </div>
                                                                                                                                                            }
                                                                                                                                                        </div>
                                                                                                                                                    }
                                                                                                                                                    {
                                                                                                                                                        //false &&
                                                                                                                                                        lead.type !== "pre-qualified" && qualifion === "pre-qualified" &&
                                                                                                                                                        <div style={{ minHeight: "21px" }}></div>
                                                                                                                                                    }
                                                                                                                                                    {
                                                                                                                                                        false &&
                                                                                                                                                        lead.type !== "pre-qualified" && qualifion === "pre-qualified" &&
                                                                                                                                                        <div className="flex flex-row justify-end">
                                                                                                                                                            <div
                                                                                                                                                                style={{ minHeight: "21px" }}
                                                                                                                                                                className="w-full"></div>
                                                                                                                                                        </div>
                                                                                                                                                    }
                                                                                                                                                    {
                                                                                                                                                        lead.type === "qualified" && qualifion === "qualified" &&
                                                                                                                                                        <div className="flex flex-row">
                                                                                                                                                            <div className="mr-3">
                                                                                                                                                                {lead.gotQualified && moment(lead.gotQualified).format("YYYY-MM-DD hh:mm a")}
                                                                                                                                                            </div>
                                                                                                                                                            {
                                                                                                                                                                lead.gotQualified &&
                                                                                                                                                                <div style={{ marginTop: "1px" }} className="flex items-center p-1 justify-center rounded-full bg-purple-500 border border-white h-5 w-5 -ml-1">
                                                                                                                                                                    <img style={{ maxWidth: '105%' }} src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                                                </div>
                                                                                                                                                            }
                                                                                                                                                        </div>
                                                                                                                                                    }
                                                                                                                                                    {
                                                                                                                                                        //false &&
                                                                                                                                                        lead.type !== "qualified" && qualifion === "qualified" &&
                                                                                                                                                        <div style={{ minHeight: "21px" }}></div>
                                                                                                                                                    }
                                                                                                                                                    {
                                                                                                                                                        false &&
                                                                                                                                                        lead.type !== "qualified" && qualifion === "qualified" &&
                                                                                                                                                        <div className="flex flex-row justify-end">
                                                                                                                                                            <div
                                                                                                                                                                style={{ minHeight: "21px" }}
                                                                                                                                                                className="w-full"></div>
                                                                                                                                                        </div>
                                                                                                                                                    }
                                                                                                                                                    {
                                                                                                                                                        lead.type === "double-qualified" && qualifion === "double-qualified" &&
                                                                                                                                                        <div className="flex flex-row">
                                                                                                                                                            <div className="mr-3">
                                                                                                                                                                {lead.gotPostQualified && moment(lead.gotPostQualified).format("YYYY-MM-DD hh:mm a")}
                                                                                                                                                            </div>
                                                                                                                                                            {
                                                                                                                                                                lead.gotPostQualified &&
                                                                                                                                                                <div style={{ marginTop: "1px" }} className="flex items-center p-1 justify-center rounded-full bg-purple-500 border border-white h-5 w-5 -ml-1">
                                                                                                                                                                    <img style={{ maxWidth: '105%' }} src={require("../../assets/images/AB_Social_internal.png")} />
                                                                                                                                                                </div>
                                                                                                                                                            }
                                                                                                                                                        </div>
                                                                                                                                                    }
                                                                                                                                                    {
                                                                                                                                                        //false &&
                                                                                                                                                        lead.type !== "double-qualified" && qualifion === "double-qualified" &&
                                                                                                                                                        <div style={{ minHeight: "21px" }}></div>
                                                                                                                                                    }
                                                                                                                                                    {
                                                                                                                                                        false &&
                                                                                                                                                        lead.type !== "double-qualified" && qualifion === "double-qualified" &&
                                                                                                                                                        <div className="flex flex-row justify-end">
                                                                                                                                                            <div
                                                                                                                                                                style={{ minHeight: "21px" }}
                                                                                                                                                                className="w-full"></div>
                                                                                                                                                        </div>
                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        }

                                                                                        {
                                                                                            agent.loading && agent.open &&
                                                                                            <div className="absolute top-0 bottom-0 left-0 right-0 z-10 flex justify-center items-center bg-white bg-opacity-50">
                                                                                                <div style={{ borderTopColor: "transparent" }}
                                                                                                    class="w-8 h-8 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                this.state.loading_users &&
                                                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-50">
                                                    <div style={{ borderTopColor: "transparent" }}
                                                        class="w-12 h-12 border-2 border-indigo-500 absolute border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.clients.length > 0 &&
                                        <div className="rounded-b-lg">
                                            <nav
                                                className="py-4 flex items-center justify-between px-6"
                                                aria-label="Pagination"
                                            >
                                                <div className="hidden sm:block">
                                                    <p className="text-sm text-gray-700">
                                                        Showing <span className="font-medium">{((this.state.page * this.state.page_count) - this.state.page_count) + 1}</span> to <span className="font-medium">{(this.state.page * this.state.page_count) > this.state[this.state.tab.value] ? this.state[this.state.tab.value] : (this.state.page * this.state.page_count)}</span> of{' '}
                                                        <span className="font-medium">{this.state[this.state.tab.value]}</span> results
                                                    </p>
                                                </div>
                                                <div className="flex-1 flex flex-row">
                                                    <div className="flex-1 sm:justify-end flex">
                                                        <Menu as="div" className="relative inline-block text-left mr-2">
                                                            <div>
                                                                <Menu.Button className={(this.state.sequence_loading ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex relative justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                    Page limit: {this.state.page_count}
                                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                </Menu.Button>
                                                            </div>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="transform opacity-0 scale-95"
                                                                enterTo="transform opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="transform opacity-100 scale-100"
                                                                leaveTo="transform opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-1">
                                                                        {
                                                                            [1, 10, 20, 30, 50, 100, 1000].map((item) => {
                                                                                return (
                                                                                    <Menu.Item>
                                                                                        {({ active }) => (
                                                                                            <div
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        page_count: item,
                                                                                                        page: 1
                                                                                                    }, () => {
                                                                                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                                                                                        this.functions.getClients();
                                                                                                    })
                                                                                                }}
                                                                                                className={classNames(
                                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                    this.state.page_count == item ? "text-purple-500" : ""
                                                                                                )}
                                                                                            >
                                                                                                {item}
                                                                                            </div>
                                                                                        )}
                                                                                    </Menu.Item>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (this.state.page !== 1) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page > 1 ? (this.state.page - 1) : 1
                                                                }, () => {
                                                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                                                    this.functions.getClients();
                                                                });
                                                            }
                                                        }}
                                                        className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Previous
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (((this.state.page) * this.state.page_count) < this.state[this.state.tab.value]) {
                                                                this.setState({
                                                                    select_all: false,
                                                                    page: this.state.page + 1
                                                                }, () => {
                                                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                                                    this.functions.getClients();
                                                                });
                                                            }
                                                        }}
                                                        className={(((this.state.page) * this.state.page_count) > this.state[this.state.tab.value] ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                                                    >
                                                        Next
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    }
                                </section>
                            }
                        </div>
                    </div >
                }

                {
                    <div className="w-full h-10 hidden sm:block"></div>
                }

            </div >
        )
    }
}

export default ListClients;
